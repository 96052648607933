import Slider, { LazyLoadTypes } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Typography } from '@mui/material';
import { useSelector } from '../../store';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { usePostHog } from 'posthog-js/react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { useEffect, useState } from 'react';
import VConsole from 'vconsole';

const BASE_URL_BANNER = 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer';

const OffersBenefits = () => {
  const posthog = usePostHog();

  const { campaigns } = useSelector(getAuthInfo);
  const { burnCoinsFeat, esimOffer, loungeBanner, premiumUserFeat } = useSelector(getFeatureFlag);
  const { tags } = useSelector(getUserInfo);
  const eligibleCampaigns = campaigns?.eligibleCampaigns;
  const campaignRewardType = eligibleCampaigns?.[0]?.campaignRewardType;
  const isPremiumUser = Array.isArray(tags) && (tags?.includes('NIYO_GOLD_PREMIUM_USER') || tags?.includes('NIYO_SILVER_PREMIUM_USER'));
  const SETTING = {
    dots: (['NXREWARD', 'FIXED']?.includes(campaignRewardType) || burnCoinsFeat) && esimOffer ? true : false,
    infinite: (['NXREWARD', 'FIXED']?.includes(campaignRewardType) || burnCoinsFeat) && esimOffer ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };
  const [startTime, setStartTime] = useState(Date.now());
  const [tapCount, setTapCount] = useState(0);

  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);


  const onClickBanner = (offer: string) => {
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Home',
        ctaAction: 'Offers',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          offer: offer
        }
      },
      posthog
    );
  }

  const handleTap = () => {
    setTapCount(prevCount => {
      const newCount = prevCount + 1;
      if (newCount === 9) {
        const vConsole = new VConsole();
        if (vConsole) {
          vConsole.showSwitch(); // Unhide vConsole after 5 taps
        }
      }
      return newCount;
    });
  }

  return (
    <Box sx={{ mt: '30px', overflowX: 'hidden' }} onClick={handleTap}>
      <Typography variant="body1" sx={{ fontWeight: 600, mb: '20px', ml: '20px' }}>
        Offers & benefits
      </Typography>
      <Box className="slider-container" sx={{ mb: '30px' }}>
        <Slider {...SETTING}>
          {tags && !isPremiumUser && premiumUserFeat && <CustomSlide banner="loyalty-earn-card.svg" onClick={() => onClickBanner('Premium user banner')} />}
          {loungeBanner && <CustomSlide banner="lounge-banner.jpg" onClick={() => onClickBanner('Lounge banner')} />}
          {esimOffer && <CustomSlide banner="esim-banner.jpg" onClick={() => onClickBanner('Esim Banner')} />}  
          {burnCoinsFeat && <CustomSlide banner="burn-75p.svg" onClick={() => onClickBanner('Burn coins banner')} />}
          {campaignRewardType === 'NXREWARD' && <CustomSlide banner="earn-30p.svg" onClick={() => onClickBanner('NX reward banner')} />}
          {campaignRewardType === 'FIXED' && <CustomSlide banner="earn-10k.svg" onClick={() => onClickBanner('Fixed reward banner')} />}
        </Slider>
      </Box>
    </Box>
  );
};

export default OffersBenefits;

const CustomSlide = ({ banner, onClick }: any) => {
  return (
    <Box sx={{ mx: '20px' }} onClick={onClick}>
      <img
        src={`${BASE_URL_BANNER}/${banner}`}
        style={{
          width: '100%',
          display: 'block',
          height: 'auto',
        }}
        alt="banner"
      />
    </Box>
  );
};
