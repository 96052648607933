import { Box, Typography } from "@mui/material";
import FilterCount from "./FilterCount";

const FilterSection = ({
  title,
  filterType,
  children,
  filterCount
}: {
  title: string,
  filterType: string,
  children: React.ReactNode,
  filterCount: number
}) => (
  <Box px='20px' mt='8px'>
    <Typography
      display='flex'
      alignItems='center'
      mb='20px'
      fontWeight='600'
    >
      {title} {filterCount > 0 && <FilterCount count={filterCount} />}
    </Typography>
    {children}
  </Box>
);

export default FilterSection;
