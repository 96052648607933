import { Box, Container, Typography } from "@mui/material";
import { OACountdown } from "./OACountdown";
import { OALoadingLottie } from "./OALoadingLottie";
import { useState } from "react";
import { OAImage } from "./OAImage";

const OAPaymentProcessed = ({
  timeOutInSeconds,
}: {
  timeOutInSeconds: number;
}) => {
  const [isTimeout, setIsTimeout] = useState<boolean>(false);

  const onComplete = () => {
    setIsTimeout(true);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        bgcolor: '#FFFFFF',
        zIndex: 9999,
      }}
    >
        {isTimeout ? (
          <OAImage src="error-icon.svg" folder="icons" alt="error" />
        ) : (
          <OALoadingLottie />
        )}
      <Typography variant="body2" sx={{ fontWeight: 600, mt: '20px', mb: '8px' }}>
          {isTimeout ? (
            'Request timed out'
          ) : (
            <OACountdown duration={timeOutInSeconds} onComplete={onComplete} />
          )}
        </Typography>
      <Container>
        <Box
          display="flex"
          alignItems="flex-start"
          sx={{ p: '15px', bgcolor: '#F4F6F5', borderRadius: '10px' }}
        >
          <Typography variant="body3" sx={{ ml: '10px' }}>
            We are currently checking the status of your payment. Please be patient while we confirm your flight booking
          </Typography>
        </Box>

      </Container>
      <Typography
        variant="body3"
        sx={{
          p: '20px 40px',
          position: 'fixed',
          bottom: 0,
          left: 0,
          textAlign: 'center',
          color: 'text.secondary',
        }}
      >
        Please do not click refresh or go back until the itinerary page is displayed.
      </Typography>
    </Box>
  )
}

export default OAPaymentProcessed;