import { useEffect, useState } from 'react';
import { Box, Container, Tooltip, Typography } from '@mui/material';
import {
  OAButton,
  OAFlightSummary,
  OAHeader,
  OAImage,
  OALoadingOverlay,
  OASwipeableDrawer,
} from '../../../components';
import { useDispatch, useSelector } from '../../../store';
import { getCancellationState } from '../../../store/slices/cancellationSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useCancelBookingMutation, useGetOrderByIdQuery } from '../../../services/bookingsApi';
import RefundBreakup from './RefundBreakup';
import { setMessage } from '../../../store/slices/snackbarSlice';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { ISegment } from '../../../type';
import OAFlightRoute from '../../../components/OAFlightRoute';
import RefundHowItWork from './RefundHowItWork';

const Cancel = () => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const { cancellationData, selectedCancelReason } = useSelector(getCancellationState);
  const [cancelBooking, { isLoading }] = useCancelBookingMutation();
  const [startTime, setStartTime] = useState(Date.now());
  const [showTooltip, setShowTooltip] = useState<boolean>(true);

  const navigate = useNavigate();
  const { orderId } = useParams();
  const { data } = useGetOrderByIdQuery<any>(orderId ?? '');
  const { refetch } = useGetOrderByIdQuery(orderId);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState<boolean>(false);
  const cancellable = cancellationData?.cancellable;

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (cancellationData?.supportRequired) {
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [cancellationData?.supportRequired]);

  const toggleCancelAlert = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'CancelBooking',
        ctaAction: 'Cancel',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsCancelDialogOpen(!isCancelDialogOpen);
  };

  const handleCancelFlight = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'CancelBooking',
        ctaAction: 'Confirm',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    const draft = {
      bookingId: data?.data?.id,
      selectedCancelReason: {
        reason: selectedCancelReason?.display,
        code: selectedCancelReason?.value,
      },
    };
    cancelBooking(draft).then((res: any) => {
      if (res?.error) {
        let errorMessage = null;
        if (res?.error?.data?.errors?.length > 0) {
          errorMessage = res?.error?.data?.errors?.map((obj: any) => obj?.message)?.join(', ');
        } else if (res?.error?.data?.errors?.length === 0 && res?.error?.data?.message) {
          errorMessage = res?.error?.data?.message;
        }
        dispatch(setMessage(errorMessage ?? 'Something went wrong, try again.'));
        refetch().then((res) => navigate(`/booking-status/${orderId}?overview`));
      } else {
        dispatch(
          setMessage(
            cancellable
              ? 'Your order has been cancelled'
              : 'Your order has been requested for cancellation'
          )
        );
        refetch().then((res) => navigate(`/booking-status/${orderId}?overview`));
      }
    });
  };

  const onwardFlightSegments = data?.data?.flights?.find((flight: any) => flight?.departureType === 'ONWARD')?.segments;
  const returnFlightSegments = data?.data?.flights?.find((flight: any) => flight?.departureType === 'RETURN')?.segments;
  const onwardFlightLayovers = data?.data?.flights?.find((flight: any) => flight?.departureType === 'ONWARD')?.layovers;
  const returnFlightLayovers = data?.data?.flights?.find((flight: any) => flight?.departureType === 'RETURN')?.layovers;

  const eventFreshChat = () => {
    if (
      window.flutter_inappwebview &&
      window.niyo_fresh_chat &&
      typeof window.niyo_fresh_chat === 'function'
    ) {

      const freshChatData = {
        appTag: 'oa_onarrival_mytrips',
        appSpoke: 'onarrival',
        appStage: 'onarrival_mytrips',
        appSubStage: `onarrival_mytrips`,
        faqTag: ['oa_onarrival_mytrips'],
      };
      const freshChatDataStringify = JSON.stringify(freshChatData);
      window.niyo_fresh_chat(freshChatDataStringify);
    } else {
      console.log('only allowed in mobile');
    }
  };

  return (
    <>
      {isLoading && <OALoadingOverlay />}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        }}
      >
        <OAHeader title="Review & Cancel"
          action={
            <Tooltip
              title={<Box textAlign='center'>Reach out to our support <br />team for any queries</Box>}
              open={cancellationData?.supportRequired ? showTooltip : false}
              placement="bottom-end"
              arrow
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-12, 8],
                    },
                  },
                ],
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: 'black',
                    borderRadius: '10px',
                    fontSize: '12px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    marginTop: '2px !important',
                    padding: '10px 10px',
                    left: '14px',
                    transformOrigin: 'left center',
                  },
                },
                arrow: {
                  sx: {
                    color: 'black',
                    borderRadius: '2px',
                    left: '-4px !important',
                    '&::before': {
                      borderRadius: '2px',
                    },
                  },
                },
              }}
            >
              <Box onClick={eventFreshChat}>
                <OAImage src="chatbot.svg" folder="icons" alt="chat" />
              </Box>
            </Tooltip>

          }
        />
      </Box>
      <Box mt="16px">
        {returnFlightSegments?.length > 0 && <Container sx={{ mb: '10px', mt: '20px' }}>
          <OAFlightRoute
            from={data?.data?.flightSearchInfo?.from?.city}
            to={data?.data?.flightSearchInfo?.to?.city}
            icon="flight-take-off.svg"
          />
        </Container>}
        {onwardFlightSegments?.map((segment: ISegment) => (
          <OAFlightSummary data={segment} layovers={onwardFlightLayovers} isConfirmItinerary={true} />
        ))}
        {returnFlightSegments?.length > 0 && <Container sx={{ mb: '10px', mt: '20px' }}>
          <OAFlightRoute
            from={data?.data?.flightSearchInfo?.to?.city}
            to={data?.data?.flightSearchInfo?.from?.city}
            icon="FlightLand"
          />
        </Container>}
        {returnFlightSegments?.map((segment: ISegment) => (
          <OAFlightSummary data={segment} layovers={returnFlightLayovers} isConfirmItinerary={true} />
        ))}
      </Box>

      <Box mt="20px" px="16px" mb="80px">
        <Typography fontWeight={600} mb="15px">
          {cancellable ? 'Refund breakup' : 'How this works'}
        </Typography>
        {cancellable ? (
          <RefundBreakup data={data} cancellationData={cancellationData} />
        ) : (
          <RefundHowItWork />
        )}
      </Box>

      <Box px="16px" position="fixed" bottom="0" pb="16px" left="0" right="0">
        <OAButton
          variant="contained"
          color="secondary"
          sx={{ width: '100%' }}
          onClick={toggleCancelAlert}
          fullWidth
        >
          {cancellable ? 'Cancel Ticket' : 'Request Cancellation'}
        </OAButton>
      </Box>
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
        open={isCancelDialogOpen}
        onClose={toggleCancelAlert}
        onOpen={toggleCancelAlert}
        isContainer={false}
      >
        <Box px="16px" mb="16px">
          <Typography variant="h6">Confirm Cancellation?</Typography>
          <Typography>You are cancelling your flight</Typography>
          {!cancellable && (
            <Typography mt="10px">
              Our support team will contact you shortly to assist you further via
              phone/email/whatsapp
            </Typography>
          )}
          <Box mt="30px">
            <OAButton onClick={handleCancelFlight} variant="contained" color="secondary" fullWidth>
              Confirm
            </OAButton>
          </Box>
        </Box>
      </OASwipeableDrawer>
    </>
  );
};

export default Cancel;
