import { PaletteColor, PaletteColorOptions, createTheme } from '@mui/material/styles';
import '@mui/material/styles';
declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    pink?: PaletteColor;
    yellow?: PaletteColor;
    orange?: PaletteColor;
    blue?: PaletteColor;
  }
  interface PaletteOptions {
    pink?: PaletteColorOptions;
    yellow?: PaletteColorOptions;
    orange?: PaletteColorOptions;
    blue?: PaletteColorOptions;
  }
}

const primaryMainColor = '#00C6A8';
const errorMainColor = '#C01100';

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryMainColor,
      light: '#D9FCF3',
      dark: '#00C6A8',
      contrastText: '#FDFDFD',
    },
    secondary: {
      main: '#0E1226',
      contrastText: '#FDFDFD',
    },
    info: {
      main: '#3A86FF',
      light: '#E5EEFF',
      // dark: '#572FBC'
    },
    success: {
      main: '#428365',
      light: '#CBF7E5',
      // dark: '#229334'
    },
    warning: {
      main: '#FF7F23',
      light: '#FFF6EF',
      dark: '#E19200',
    },
    error: {
      main: errorMainColor,
      light: '#FFE5E2',
      dark: '#7C1664',
      contrastText: '#fff',
    },
    grey: {
      800: '#5A6068',
      700: '#C6CBCA',
      600: '#E2E2E2',
      500: '#F4F6F5',
    },
    pink: {
      main: '#DC5FB8',
      light: '#FFE9F5',
      dark: '#7C1664',
      contrastText: '#fff',
    },
    yellow: {
      main: '#E9C243',
      light: '#FFF4CE',
      dark: '#765A1A',
      contrastText: '#fff',
    },
    orange: {
      main: '#F0935F',
      light: '#FFEACE',
      dark: '#8E3214',
      contrastText: '#fff',
    },
    blue: {
      main: '#6F8DDB',
      light: '#E6EEFF',
      dark: '#13387E',
      contrastText: '#fff',
    },
    text: {
      primary: '#14191F',
      secondary: '#5A6068',
    },
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif', 'Arial'].join(','),
    allVariants: {
      color: '#14191F', // Use the same color defined in palette.text.primary
    },
    h2: {
      fontSize: '1.25rem',
      fontWeight: 600,
      // color: primaryMainColor,
      lineHeight: '28px',
      letterSpacing: 0,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 600,
      // color: primaryMainColor,
      lineHeight: '24px',
      letterSpacing: 0,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
      // color: primaryMainColor,
      lineHeight: '22px',
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      // color: primaryMainColor,
      lineHeight: '20px',
    },
  },

  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'body3' },
          style: {
            fontSize: '0.75rem',
            fontWeight: '400',
            lineHeight: '20px',
            // color: primaryMainColor,
          },
        },
        {
          props: { variant: 'body4' },
          style: {
            fontSize: '0.625rem',
            fontWeight: '400',
            lineHeight: '15px',
            // color: primaryMainColor,
            letterSpacing: '0.15px',
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderBottom: '1px solid #E6E6E6',
          boxShadow: 'none',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          paddingLeft: '20px',
          paddingRight: '20px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          minHeight: '50px',
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: '20px',
          padding: '15px',
          borderRadius: '50px',
          boxShadow: 'none',
          minWidth: '150px',
          '&.Mui-disabled': {
            backgroundColor: '#C7CCCA',
            color: '#FDFDFD',
            boxShadow: 'none !important',
          },
          '&:hover': {
            boxShadow: 'none !important',
          },
        },
        sizeSmall: {
          padding: '6px 12px',
          fontSize: '0.875rem',
          minWidth: 'auto',
          fontWeight: 500,
          lineHeight: '24px',
          borderRadius: '25px',
          minHeight: 'auto',
          boxShadow: 'none',
        },
        // Custom styles for small button
        // sizeSmall: {
        //   padding: '8px 12px',
        //   fontSize: '0.875rem',
        //   minWidth: '100px',
        //   fontWeight: 500,
        //   lineHeight: '24px',
        //   borderRadius: '25px',
        // },
        // // Custom styles for medium button
        // sizeMedium: {
        //   padding: '12px',
        //   fontSize: '1rem',
        //   minWidth: '100px',
        //   fontWeight: 500,
        //   lineHeight: '24px',
        //   borderRadius: '40px',
        // },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'inherit !important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#5A6068',
          fontSize: '0.875rem',
          '&.Mui-focused': {
            color: '#5A6068',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '0.875rem',
          fontWeight: 400,
          color: '#14191F',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          border: '1px solid #C7CCCA',
          borderRadius: '10px',
          backgroundColor: '#FDFDFD',
          '&:hover': {
            borderColor: '#00C6A8',
            backgroundColor: '#fff',
          },
          '&.Mui-focused': {
            borderColor: '#00C6A8', // Secondary main color
            backgroundColor: '#fff',
          },
          '&.Mui-error': {
            borderColor: errorMainColor,
            backgroundColor: '#fff',
          },
          '&:after': {
            content: 'none',
          },
          '&:before': {
            content: 'none',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '1rem',
          fontWeight: 400,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: '100%',
          height: 'auto',
          backgroundColor: '#FFFFFF',
          boxShadow: 'none',
          scrollBehavior: 'smooth',
          borderRadius: '24px 24px 0 0',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          fontWeight: 400,
          borderRadius: '30px',
          borderColor: '#DEE2E1',
          color: '#14191F',
          minHeight: '32px',
          paddingLeft: '10px',
          paddingRight: '10px',
          '& .MuiChip-icon': {
            marginRight: '4px',
            marginLeft: '0',
          },
          '& .MuiChip-label': {
            paddingLeft: '4px',
            paddingRight: '4px',
          },
          '&.MuiChip-colorSecondary': {
            backgroundColor: '#E5F9F6', // Replace with your color code
            color: '#00C6A1', // Replace with your color code
          },
          '&.MuiChip-colorInfo': {
            backgroundColor: '#E5EEFF',
            color: '#3A86FF',
          },
          '&.MuiChip-colorSuccess': {
            backgroundColor: '#CBF7E5', // Replace with your color code
            color: '#428365', // Replace with your color code
          },
          '&.MuiChip-colorWarning': {
            backgroundColor: '#FFF6EF', // Replace with your color code
            color: '#FF7F23', // Replace with your color code
          },
          '&.MuiChip-colorError': {
            backgroundColor: '#FBEFEF', // Replace with your color code
            color: errorMainColor, // Replace with your color code
          },
          '&.MuiChip-colorOrange': {
            backgroundColor: '#FFEACE',
            color: '#8E3214',
          },
          '&.MuiChip-colorBlue': {
            backgroundColor: '#E6EEFF',
            color: '#13387E',
          },
          '&:active': {
            boxShadow: 'none',
          },
          '& .MuiTouchRipple-child': {
            backgroundColor: 'rgba(0, 198, 161, 0.3)', // Light green ripple
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '6px 16px', // Modify padding
        },
        message: {
          padding: 0,
          color: primaryMainColor,
          fontSize: '0.75rem',
          lineHeight: '16px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#E2E2E2', // Replace with your desired color
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          bgcolor: '#FDFDFD',
          borderRadius: '10px',
          boxShadow: 'none',
          padding: '15px',
          border: '1px solid #DEE2E1',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#14191F', // Use your desired color here
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          // Unselected tab font color
          color: '#5A6068', // Example: grey color for unselected tabs
          '&.Mui-selected': {
            // Selected tab font color
            color: '#14191F', // Example: red color for the selected tab
          },
          '&:hover': {
            // Optional: hover state color
            color: '#14191F', // Example: slightly darker red on hover
            opacity: 1,
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          '.MuiSnackbarContent-root': {
            borderRadius: '10px',
            padding: '10px 20px',
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        // Target the root of the message
        message: {
          padding: 0,
          width: '100%',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          '.MuiStepConnector-vertical': {
            marginLeft: '6px',
          },
          '.MuiStepLabel-vertical': {
            paddingTop: '0',
            paddingBottom: '0',
          },
        },
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#F4F6F5',
          borderRadius: '4px',
        },
      },
    },
  },
});
