import { FC, useEffect, useState } from 'react';
import { OAChip, OARadioGroup, OASwipeableDrawer } from '../../../components';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSelector } from 'react-redux';
import { getSearchFlightState, getSortState } from '../../../store/slices/searchFlightSlice';
import { SORT_OPTIONS } from '../../../constants';
import { Box } from '@mui/material';
import OASwitchComponent from '../../../components/OASwitchComponent';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';

interface SortProps {
  currentSortTab: number;
  setCurrentSortTab: (value: number) => void;
  onClick: (value: string) => void;
  onClickClearSort: () => void;
}

const Sort: FC<SortProps> = ({ currentSortTab, setCurrentSortTab, onClick, onClickClearSort }) => {
  const sortValue = useSelector(getSortState);
  const { data } = useSelector(getSearchFlightState);
  const { travelDetail } = useSelector(getUserInputState);
  const posthog = usePostHog();
  const [startTime, setStartTime] = useState(Date.now());

  const from = travelDetail?.from?.iata;
  const to = travelDetail?.to?.iata;

  const dataOnward = data?.results?.onward;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sortData, setSortData] = useState<any>({
    onward: sortValue,
    return: sortValue,
  });

  const toggleLabes = [`${from} -> ${to}`, `${to} -> ${from}`];

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    setSortData((prevState: any) => ({
      ...prevState,
      [currentSortTab === 0 ? 'onward' : 'return']: event.target.value,
    }));

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'Sort',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          sortBy: event.target.value,
        },
      },
      posthog
    );

    onClick(event.target.value);
    toggleDrawer();
  };

  const handleToggleSwitch = (value: number) => {
    setCurrentSortTab(value);
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    <>
      <OAChip
        icon={<FilterListIcon fontSize="small" sx={{ color: '#5A6068 !important' }} />}
        label="Sort"
        onClick={toggleDrawer}
        selected={sortValue}
        disabled={dataOnward?.length > 0 ? false : true}
        sx={{ ml: '20px' }}
      />
      <OASwipeableDrawer
        title={'Sort by'}
        open={isOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        {travelDetail?.tripType === 'R' && data?.results?.return?.length > 0 && (
          <Box mb="20px">
            <OASwitchComponent
              value={currentSortTab}
              onChange={handleToggleSwitch}
              labels={toggleLabes}
            />
          </Box>
        )}
        <OARadioGroup
          label=""
          options={SORT_OPTIONS}
          value={currentSortTab === 0 ? sortData?.onward : sortData?.return}
          onChange={handleSelectClass}
        />
        <Box sx={{ height: '24px' }}></Box>
        {/* <OAButton
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ my: '24px' }}
          onClick={handleApplyBtn}
        >
          Apply
        </OAButton> */}
      </OASwipeableDrawer>
    </>
  );
};

export default Sort;
