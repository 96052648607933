import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import FlightCard from './FlightCard';
import { IFlight } from '../../type';
import { VariableSizeList } from 'react-window';
import { OAImage, OASwipeableDrawer } from '../../components';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getUserInfo } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getUserInputState } from '../../store/slices/userInputSlice';
import FlightLabel from './FlightLabel';
import { eventsTracker } from '../../utils/ctEventsTracking';
import posthog from 'posthog-js';
import { sortFlights } from '../../utils/sortFlights';

interface FlightsListProps {
  flights: IFlight[];
  onFlightSelect: (flight: IFlight) => void;
  tripType: 'R' | 'O';
  isInternational: boolean;
  selectedFlight: IFlight;
  fastestFlight: IFlight;
  lowestPriceFlight: IFlight;
  isFastestAndLowestSame: boolean;
  nearbyAirports: any;
  cheapestNonStopFlight?: IFlight;
  filters?: any;
  sort?: any;
}

const FlightsList: React.FC<FlightsListProps> = ({
  flights,
  onFlightSelect,
  tripType,
  isInternational,
  fastestFlight,
  lowestPriceFlight,
  isFastestAndLowestSame,
  nearbyAirports,
  cheapestNonStopFlight,
  filters,
  sort
}) => {
  const listRef = useRef<VariableSizeList>(null);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins, tags } = useSelector(getUserInfo);
  const { burnCoinsFeat, noConvenienceFeeBanner, esimOffer, premiumUserFeat } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const [selectedGroup, setSelectedGroup] = useState<IFlight[]>([]);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const isPremiumUser = Array.isArray(tags) && tags?.includes('NIYO_GOLD_PREMIUM_USER');
  const isPremiumUserSilver = Array.isArray(tags) && tags?.includes('NIYO_SILVER_PREMIUM_USER');

  const handleSimilarFlightClick = (group: IFlight[], tripType: 'R' | 'O') => {
    setSelectedGroup(group);
    setOpenDrawer(true);
  };

  const { similarFlights, unsimilarFlights } = useMemo(() => {
    const groupedFlights: { [key: string]: IFlight[] } = {};

    flights?.forEach(flight => {
      if (flight?.segments && flight?.segments?.length > 0) {
        const airlineCode = flight?.segments[0]?.airline?.code;
        const key = `${airlineCode}-${flight?.price?.value}`;
        if (!groupedFlights[key]) {
          groupedFlights[key] = [];
        }
        groupedFlights[key].push(flight);
      }
    });

    // Just identify similar groups and unsimilar flights without sorting
    const similar = Object.values(groupedFlights)?.filter(group => group?.length > 1);
    const similarFlightIds = new Set(similar?.flat()?.map(flight => flight?.id));
    const unsimilar = flights?.filter(flight => !similarFlightIds?.has(flight?.id));

    return { similarFlights: similar, unsimilarFlights: unsimilar };
  }, [flights]);

  // combined sorted array of flight items
  const sortedFlightItems = useMemo(() => {
    const items: Array<{ type: 'similar' | 'unsimilar', flight: IFlight, group?: IFlight[] }> = [];

    // adding all flights to items array
    similarFlights?.forEach(group => {
      items.push({
        type: 'similar',
        flight: group[0],
        group: group
      });
    });

    unsimilarFlights?.forEach(flight => {
      items.push({
        type: 'unsimilar',
        flight: flight
      });
    });

    // converting items array to flights array for sorting
    const flightsToSort = items?.map(item => item?.flight);
    
    // sorting flights using existing sortFlights function
    const sortedFlights = sortFlights(flightsToSort, sort || 'plh');
    
    // map back to original format with type and group info
    return sortedFlights?.map(flight => 
      items?.find(item => item?.flight?.id === flight?.id)!
    );

  }, [similarFlights, unsimilarFlights, flights, filters, sort]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [isCoinsApplied, campaigns, coins, burnCoinsFeat]);


  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleCloseBottomDrawer = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker({
      flowName: 'Flight',
      screenName: 'SRP',
      ctaAction: 'SRP_MoreOptionsClose',
      screenDuration: totalScreenDuration?.toString(),
      UserAction: {
        userAction: 'User has closes the bottom sheet (tap out/close icon)'
      }
    }, posthog)
    setOpenDrawer(false)
  }
  const [cardHeights, setCardHeights] = useState<{ [key: number]: number }>({});

  const getItemSize = useCallback((index: number) => {
    if (index === 2 && noConvenienceFeeBanner && (isPremiumUser || isPremiumUserSilver) && premiumUserFeat) {
      return (isInternational && tripType === 'R') ? 124 : 104; // Height for the convenience fee banner
    }
    if (index === 6 && isInternational && esimOffer) {
      return (isInternational && tripType === 'R') ? 124 : 104; // Height for the eSIM banner
    }

    // Use the dynamically calculated height if available
    if (cardHeights[index]) {
      return cardHeights[index];
    }

    // Fallback to estimated height if not yet calculated
    const baseHeight = 175; // Base height for a flight card
    const campaignHeight = campaigns?.eligibleCampaigns?.length > 0 ? 30 : 0;
    const coinHeight = (isCoinsApplied && coins > 0 && burnCoinsFeat) ? 30 : 0;

    let additionalHeight = 0;

    const flightIndex = noConvenienceFeeBanner && index > 2 ? index - 1 : index;
    const flight = flights[flightIndex];
    if (flight && similarFlights.some(group => group.includes(flight))) {
      additionalHeight += 30; // Height for the "same price options" button
    }

    return baseHeight + campaignHeight + coinHeight + additionalHeight;
  }, [noConvenienceFeeBanner, isInternational, tripType, esimOffer, campaigns, isCoinsApplied, coins, burnCoinsFeat, flights, similarFlights, cardHeights]);

  const isCheapestAndFastest = (flight: IFlight) => cheapestNonStopFlight && flight?.id === cheapestNonStopFlight?.id && flight?.id !== lowestPriceFlight?.id && fastestFlight && flight?.id === fastestFlight?.id && isInternational;

  const renderFlightLabels = (flight: IFlight, international: boolean) => (
    <>
      {(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id) ? (
        <>
          <FlightLabel
            bgColor='#215039'
            icon='percent.svg'
            text='Cheapest'
            customStyles={{ right: '90px', zIndex: 10 }}
          />
          <FlightLabel
            bgColor='#F0935F'
            icon='fastest.svg'
            text='Fastest'
            customStyles={{ right: '16px', zIndex: 10 }}
          />
        </>
      ) : (
        <>
          {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
            <FlightLabel
              bgColor='#215039'
              icon='percent.svg'
              text='Cheapest'
              customStyles={{ right: '16px', zIndex: 10 }}
            />
          )}
          {cheapestNonStopFlight && flight?.id === cheapestNonStopFlight?.id && flight?.id !== lowestPriceFlight?.id && (
            <FlightLabel
              bgColor='#215039'
              icon='percent.svg'
              text='Cheapest Non-Stop'
              customStyles={{ right: isCheapestAndFastest(flight) ? '90px' :'16px', zIndex: 10 }}
            />
          )}
          {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
            <FlightLabel
              bgColor='#F0935F'
              icon='fastest.svg'
              text='Fastest'
              customStyles={{ right: '16px', zIndex: 10 }}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <Grid container>
      <Grid item xs={12} sx={{ height: '100%', overflowY: 'auto' }} mb="35px" pt="10px">
        {/* First 2 flights */}
        {sortedFlightItems?.slice(0, 2)?.map((flightItem, index) => (
          <Box
            key={flightItem?.flight?.id}
            mx='16px'
            mt='8px'
            mb="20px"
            position='relative'
          >
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? "0px" : undefined }}
            />
            {flightItem?.type === 'similar' && flightItem?.group && flightItem?.group?.length > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    backgroundColor: '#009E82',
                    color: 'white',
                    padding: '4px 8px',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTopRightRadius: '0px',
                    borderTopLeftRadius: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                >
                  <Typography variant='body4' color='white'>
                    +{flightItem?.group?.length - 1} same price options
                  </Typography>
                  <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
                </Box>
              </Box>
            )}
          </Box>
        ))}

        {/* Convenience Fee Banner */}
        {noConvenienceFeeBanner && (
          <Box sx={{ marginBottom: '15px' }}>
            {(isPremiumUser || isPremiumUserSilver) && premiumUserFeat ? (
              <OAImage 
                src={isPremiumUserSilver ? 'niyo-convenience-silver.svg' : 'niyo-convenience-gold.svg'} 
                folder='offer' 
                alt='no-convenience-fee' 
                width='100%' 
              />
            ) : (
              <OAImage src='no-convenience-fee.svg' folder='icons' alt='no-convenience-fee' width='100%' />
            )}
          </Box>
        )}

        {/* Remaining flights */}
        {sortedFlightItems?.slice(2, 5)?.map((flightItem, index) => (
          <Box
            key={flightItem?.flight?.id}
            mx='16px'
            mb="20px"
            position='relative'
          >
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? "0px" : undefined }}
            />
            {flightItem?.type === 'similar' && flightItem?.group && flightItem?.group?.length > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    backgroundColor: '#009E82',
                    color: 'white',
                    padding: '4px 8px',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTopRightRadius: '0px',
                    borderTopLeftRadius: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                >
                  <Typography variant='body4' color='white'>
                    +{flightItem?.group?.length - 1} same price options
                  </Typography>
                  <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
                </Box>
              </Box>
            )}
          </Box>
        ))}

        {/* ESIM Banner */}
        {esimOffer && isInternational && (
          <Box mb='15px'>
            <OAImage src='esim-srp.svg' folder='offer' alt='esim-banner' width='100%' />
          </Box>
        )}
        {sortedFlightItems?.slice(5)?.map((flightItem, index) => (
          <Box
            key={flightItem?.flight?.id}
            mx='16px'
            mb="20px"
            position='relative'
          >
            {renderFlightLabels(flightItem?.flight, isInternational)}
            <FlightCard
              data={flightItem?.flight}
              onClick={() => onFlightSelect(flightItem?.flight)}
              tripType={tripType}
              isInternational={isInternational}
              nearbyAirports={nearbyAirports}
              sx={{ borderBottomRightRadius: flightItem?.type === 'similar' ? "0px" : undefined }}
            />
            {flightItem?.type === 'similar' && flightItem?.group && flightItem?.group?.length > 1 && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box
                  sx={{
                    backgroundColor: '#009E82',
                    color: 'white',
                    padding: '4px 8px',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderTopRightRadius: '0px',
                    borderTopLeftRadius: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => handleSimilarFlightClick(flightItem?.group!, tripType)}
                >
                  <Typography variant='body4' color='white'>
                    +{flightItem?.group?.length - 1} same price options
                  </Typography>
                  <OAImage src='right-chevron.svg' folder='icons' alt='right' width='16px' height='16px' />
                </Box>
              </Box>
            )}
          </Box>
        ))}
      </Grid>
      
      {/* Bottom Drawer */}
      <OASwipeableDrawer
        open={openDrawer}
        onClose={handleCloseBottomDrawer}
        onOpen={() => setOpenDrawer(true)}
        title="Same price options"
        isContainer={false}
        maxHeight={90}
      >
        <Box bgcolor='#F4F6F5'>
          {selectedGroup?.map((flight) => (
            <Box key={flight?.id} sx={{ marginBottom: '15px' }} px='16px' mt='8px'>
              {(flight?.id === fastestFlight?.id) && (flight?.id === lowestPriceFlight?.id) ? (
                <>
                  <FlightLabel
                    bgColor='#215039'
                    icon='percent.svg'
                    text='Cheapest'
                    customStyles={{ top: '90px', zIndex: 99, right: '110px' }}
                  />
                  <FlightLabel
                    bgColor='#F0935F'
                    icon='fastest.svg'
                    text='Fastest'
                    customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                  />
                </>
              ) : (
                <>
                  {lowestPriceFlight && flight?.id === lowestPriceFlight?.id && (
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Cheapest'
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                  {cheapestNonStopFlight && flight?.id === cheapestNonStopFlight?.id && flight?.id !== lowestPriceFlight?.id && (
                    <FlightLabel
                      bgColor='#215039'
                      icon='percent.svg'
                      text='Cheapest Non-Stop'
                      customStyles={{ top: '90px', zIndex: 99, right: isCheapestAndFastest(flight) ? '110px' : '32px' }}
                    />
                  )}
                  {fastestFlight && flight?.id === fastestFlight?.id && isInternational && (
                    <FlightLabel
                      bgColor='#F0935F'
                      icon='fastest.svg'
                      text='Fastest'
                      customStyles={{ top: '90px', zIndex: 99, right: '32px' }}
                    />
                  )}
                </>
              )}
              <FlightCard
                data={flight}
                onClick={() => {
                  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
                  eventsTracker({
                    flowName: 'Flight',
                    screenName: 'SRP',
                    ctaAction: 'SRP_MoreOptionsSelect',
                    screenDuration: totalScreenDuration?.toString(),
                    UserAction: {
                      userAction: 'User has selected a flight from the bottom sheet'
                    }
                  }, posthog)
                  onFlightSelect(flight)
                }}
                tripType={tripType}
                isInternational={isInternational}
                nearbyAirports={nearbyAirports}
              />

            </Box>
          ))}
        </Box>
      </OASwipeableDrawer>
    </Grid>
  );
};

export default FlightsList;