import { useState, useEffect } from 'react';
const useKeyboardVisibility = (): boolean => {
  const [isKeyboardVisible, setKeyboardVisible] = useState<boolean>(false);
  useEffect(() => {
    const initialHeight = window.innerHeight;
    const onResize = () => {
      const currentHeight = window.innerHeight;
      const threshold = 150; // Adjust this threshold based on your observations
      console.log(
        'initialHeight, currentHeight, threshold',
        initialHeight,
        currentHeight,
        threshold
      );
      const heightCal = initialHeight - currentHeight;
      setKeyboardVisible(heightCal > threshold);
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return isKeyboardVisible;
};
export default useKeyboardVisibility;