import { createSlice } from '@reduxjs/toolkit';

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    message: '',
    severity: 'default',
  },
  reducers: {
    setMessage: (state, action) => {
      console.log('action?.payload', action?.payload);
      if (action?.payload?.message) {
        state.message = action?.payload?.message;
        state.severity = action?.payload?.severity || 'default';
      } else {
        state.message = action?.payload;
      }
    },
    clearMessage: (state) => {
      state.message = '';
      state.severity = 'default';
    },
  },
});

export const { setMessage, clearMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;
