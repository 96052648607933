import { authApi } from '../services/authApi';
import { store } from '../store';
import { setCredentials } from '../store/slices/authSlice';

export const userLogin = async (reqObj: any) => {
  try {
    console.log('userLogin -> ', reqObj);

    if (reqObj?.id && reqObj?.token) {
      const resultAction: any = await store.dispatch(authApi.endpoints.createUser.initiate(reqObj));
      console.log('resultAction -> ', resultAction);

      if (resultAction?.data?.httpStatus === 200) {
        const cred = {
          isLoggedIn: true,
          user: resultAction?.data?.data,
          token: reqObj?.token || null,
        };
        store.dispatch(setCredentials(cred));
        return { success: true, message: 'Login successful', data: cred.user };
      } else {
        return {
          success: false,
          message: 'User API failed',
          httpStatus: resultAction?.data?.httpStatus,
        };
      }
    } else {
      console.error(`login field missing!!` + JSON.stringify(reqObj));
      return { success: false, message: 'Required fields are missing' };
    }
  } catch (error: any) {
    // Error handling code
    console.error('user API catch', error);
    return { success: false, message: 'An error occurred', error: error };
  }
};
