import { Box, Typography } from "@mui/material";

const FilterLabel = ({ label, total }: { label: string; total?: number }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ position: 'relative' }}>
      <Typography variant="body2">{label}</Typography>
      {total && total !== 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            bgcolor: 'primary.main',
            ml: '8px',
            height: '16px',
            width: '16px',
            borderRadius: '50%',
          }}
        >
          <Typography variant="body4" sx={{ color: 'primary.contrastText' }}>
            {total}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default FilterLabel;
