import { createApi } from '@reduxjs/toolkit/query/react';
import { baseApi } from './baseApi';
import {
  CANCELLATION_INFO,
  CANCEL_ORDER,
  CANCEL_TRANSACTION,
  CREATE_FLIGHT_ORDER,
  DOWNLOAD_ETICKET,
  MY_BOOKINGS,
  NIYO_PREMIUM_PLANS,
} from '../store/apiUrls';
import { compareDesc, parseISO } from 'date-fns';

export const getBookingsApi = createApi({
  reducerPath: 'getBookingsApi',
  baseQuery: baseApi,
  tagTypes: ['Bookings'],
  endpoints: (builder) => ({
    getBookingsList: builder.query({
      query: () => `${MY_BOOKINGS}`,
      keepUnusedDataFor: 5 * 60,
      providesTags: ['Bookings'],
      transformResponse: (response: { data: Array<any> }) => {
        return response.data.sort((a, b) =>
          compareDesc(parseISO(b.tripStartDate), parseISO(a.tripStartDate))
        );
      },
    }),
    getOrderById: builder.query({
      query: (bookingId) => `${CREATE_FLIGHT_ORDER}/${bookingId}`,
      keepUnusedDataFor: 0,
    }),
    cancellationInfo: builder.query({
      query: (bookingId) => ({
        url: `${CANCELLATION_INFO}/${bookingId}`,
        method: 'GET',
      }),
    }),
    cancelBooking: builder.mutation({
      query: (request) => ({
        url: `${CANCEL_ORDER}/${request.bookingId}`,
        method: 'POST',
        body: request?.selectedCancelReason,
      }),
      invalidatesTags: ['Bookings'],
    }),
    cancelTransactionApi: builder.mutation({
      query: (id) => ({
        url: `${CANCEL_TRANSACTION}/${id}`,
        method: 'PUT',
      }),
    }),
    getEticket: builder.query({
      query: (orderId) => `${DOWNLOAD_ETICKET}/${orderId}`,
    }),
    getPremiumPlans: builder.query({
      query: () => NIYO_PREMIUM_PLANS
    })
  }),
});

export const {
  useGetBookingsListQuery,
  useGetOrderByIdQuery,
  useCancellationInfoQuery,
  useCancelBookingMutation,
  useCancelTransactionApiMutation,
  useLazyGetEticketQuery,
  useGetPremiumPlansQuery
} = getBookingsApi;
