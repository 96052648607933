export function compareVersions(v1: string, v2: string): number {
    const v1Parts: number[] = v1.split('.').map(Number);
    const v2Parts: number[] = v2.split('.').map(Number);

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
        const v1Part = v1Parts[i] || 0;  // Default to 0 if part is missing
        const v2Part = v2Parts[i] || 0;  // Default to 0 if part is missing

        if (v1Part > v2Part) {
            return 1;  // v1 is greater
        } else if (v1Part < v2Part) {
            return -1;  // v1 is smaller
        }
    }

    return 0;  // Versions are equal
}