// ------- date format section ------------
export const BE_DATE = 'yyyy-MM-dd';
export const FE_CARD_HEADER = 'EEE, dd MMM, yyyy';

export const TEST_COINS_WEB = 0;
export const COIN_VALUE_IN_RS = 10; // 10 coins equal to 1 Rs
export const DISCOUNT_PERCENTAGE = 0.75; // 0 TO 100 Percentage

export const flightClassOptions = [
  { value: 'ECONOMY', label: 'Economy' },
  { value: 'BUSINESS', label: 'Business' },
  { value: 'FIRST', label: 'First' },
  { value: 'PREMIUM_ECONOMY', label: 'Premium Economy' },
];

export const SORT_OPTIONS = [
  { value: 'plh', label: 'Price - low to high' },
  { value: 'phl', label: 'Price - high to low' },
  { value: 'ed', label: 'Early departure' },
  { value: 'ld', label: 'Late  departure' },
  { value: 'dsf', label: 'Duration (shortest first)' },
];

export const flightTabs = [
  { value: 'UPCOMING', label: 'Upcoming' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'FAILED', label: 'Failed' },
];

export const bookingStatusLabels = [
  {
    value: 'BOOKING_IN_PROGRESS',
    label: 'Awaiting PNR',
    type: 'orange',
    message: 'Have a great journey!',
  },
  {
    value: 'PROCESSING_OFFLINE',
    label: 'Awaiting PNR', //same as booking in progress
    type: 'orange',
    message: 'Have a great journey!',
  },
  {
    value: 'PAYMENT_FAILED',
    label: 'Payment Failed',
    type: 'error',
    message: 'Your payment has failed',
  },
  { value: 'BOOKING_CONFIRMED', label: 'Ongoing', type: 'blue', message: 'Have a great journey!' },
  { value: 'CANCELLED', label: 'Cancelled', type: 'error', message: 'Your booking was cancelled' },
  {
    value: 'BOOKING_FAILED',
    label: 'Booking failed',
    type: 'error',
    message: 'Your booking has failed',
  },
  {
    value: 'COMPLETED',
    label: 'Completed',
    type: 'success',
    message: 'Hope you had a great journey!',
  },
  {
    value: 'CANCELLATION_REQUESTED',
    label: 'Cancel requested',
    type: 'orange',
    message: 'Your cancellation is requested',
  },
];

export const MOCK_PAYMENT_STATUS = [
  { value: 'success', label: 'Success' },
  { value: 'failed', label: 'Failed' },
  // { value: 'refunded', label: 'Refunded' },
  // { value: 'progress', label: 'Progress' },
];

export const TEST_EMAILS = [
  'pawanshetty@outlook.com',
  'adhikarisuvidh@gmail.com',
  'adhikarisuvidh@gmail.com',
  'rvndk7@gmail.com',
  'arjunbharti774@gmail.com',
  'vishalbhimani05@gmail.com',
  'shishirmns718@gmail.com',
  'arvind@onarrival.travel',
  'vin.nair11@gmail.com'
];

export const NATIONALITY_OPTIONS = [
  { value: 'IN', label: 'India' },
  { value: 'AU', label: 'Australian' },
  { value: 'FR', label: 'France' },
  { value: 'DE', label: 'Germany' },
  { value: 'JP', label: 'Japan' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SG', label: 'Singapore' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'UK', label: 'United Kingdom' },
  { value: 'US', label: 'United States of America' },
];

