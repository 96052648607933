import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { size, flatten, values } from 'lodash';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import { OAAirlineLogo, OAButton, OAChip, OAImage, OALoadingOverlay, OASwipeableDrawer } from '../../../components';
import { getFilterState, getSearchFlightState } from '../../../store/slices/searchFlightSlice';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import FilterCheckboxItem from './FilterCheckboxItem';
import FilterLabel from './FilterLabel';
import FilterSection from './FilterSection';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';


interface FilterProps {
  filterData: any;
  filtersCount: any;
  countOfStops: any;
  countOfAirports: any;
  setFilterData: (values: any) => void;
  onClick: (filterData: any) => void;
  onClickClearFilter: () => void;
}

const Filter: React.FC<FilterProps> = ({
  filterData,
  filtersCount,
  countOfStops,
  countOfAirports,
  setFilterData,
  onClick,
  onClickClearFilter,
}) => {
  const { data } = useSelector(getSearchFlightState);
  const { travelDetail } = useSelector(getUserInputState);
  const filterValue = useSelector(getFilterState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAllAirlines, setShowAllAirlines] = useState<boolean>(false);
  const [localFilterData, setLocalFilterData] = useState<any>(filterData);
  const [airlinePrices, setAirlinePrices] = useState<{ [key: string]: number }>({});
  const [startTime, setStartTime] = useState(Date.now());
  const posthog = usePostHog();


  const isInternationalReturn = Boolean(data?.international && travelDetail?.tripType === 'R' && travelDetail?.tripEnd?.date);

  useEffect(() => {
    if (data?.results) {
      const prices: { [key: string]: number } = {};

      data?.results?.onward?.forEach((item: any) => {
        const airlineCode = item?.airlineCode;
        const price = item?.price?.value;
        if (price && (!prices[airlineCode] || price < prices[airlineCode])) {
          prices[airlineCode] = price;
        }
      });

      data?.results?.return?.forEach((item: any) => {
        const airlineCode = item?.airlineCode;
        const price = item?.price?.total;
        if (price && (!prices[airlineCode] || price < prices[airlineCode])) {
          prices[airlineCode] = price;
        }
      });

      setAirlinePrices(prices);
    }
  }, [data]);

  const toggleDrawer = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setLocalFilterData(filterData || null);
    }
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { checked, value: targetValue } = event.target;

    setLocalFilterData((currentFilterData: any) => {
      const updatedFilter = { ...currentFilterData };

      if (!updatedFilter[name]) {
        updatedFilter[name] = [];
      }

      if (checked) {
        if (!updatedFilter[name]?.includes(targetValue)) {
          updatedFilter[name] = [...updatedFilter[name], targetValue];
        }
      } else {
        updatedFilter[name] = updatedFilter[name]?.filter((a: any) => a !== targetValue);
        if (updatedFilter[name]?.length === 0) {
          delete updatedFilter[name];
        }
      }

      return updatedFilter;
    });
  };

  const handleApplyBtn = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'ApplyFilter',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          localFilterData,
        },
      },
      posthog
    );
    setIsLoading(true);
    toggleDrawer(false);
    setFilterData(localFilterData);
    onClick(localFilterData);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleClearBtn = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'ClearFilter',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsLoading(true);
    onClickClearFilter();
    setLocalFilterData({});
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const totalFilter = useMemo(() => size(flatten(values(filterValue))), [filterValue]);

  const from = travelDetail?.from?.city;
  const to = travelDetail?.to?.city;

  const getFilterCount = (filterType: string) => {
    return localFilterData?.[filterType]?.length || 0;
  };

  useEffect(() => {
    setLocalFilterData(filterData || null);
  }, [filterData]);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const renderStopsFilter = () => (
    <FilterSection title="Stops" filterType="stops" filterCount={getFilterCount('stops')}>
      <Box display='flex' alignItems='center' justifyContent='space-between' gap='8px'>
        {data?.meta?.stops?.map((stop: any) => {
          const count = countOfStops[stop.value] || 0;
          const isDisabled = count === 0;
          return (
            <Box
              key={stop.value}
              p='10px'
              border={localFilterData?.stops?.includes(stop.value) ? '1px solid #009E82' : '1px solid #E2E2E2'}
              borderRadius='8px'
              bgcolor={localFilterData?.stops?.includes(stop.value) ? '#D9FCF3' : 'transparent'}
              width='100%'
              textAlign='center'
              onClick={() => {
                if (!isDisabled) {
                  handleChangeFilter({ target: { checked: !localFilterData?.stops?.includes(stop.value), value: stop.value } } as React.ChangeEvent<HTMLInputElement>, 'stops')
                }
              }}
              sx={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              <Typography
                variant='body2'
                color={isDisabled ? '#8C9097' : (localFilterData?.stops?.includes(stop.value) ? '#009E82' : 'revert')}
                fontWeight='600'
              >
                {stop.value === '0' ? 'Direct' : `${stop.value} stop`}
                {' '}
                <Typography
                  variant='body3'
                  color={isDisabled ? '#8C9097' : (localFilterData?.stops?.includes(stop.value) ? '#009E82' : 'text.secondary')}
                  fontWeight='400'
                >
                  ({count})
                </Typography>
              </Typography>
            </Box>
          );
        })}
      </Box>
    </FilterSection>
  );

  const renderAirlinesFilter = () => (
    <FilterSection title="Airlines" filterType="airlines" filterCount={getFilterCount('airlines')}>
      {data?.meta?.airlines?.slice(0, showAllAirlines ? undefined : 4)
        ?.filter((airline: any) => airlinePrices[airline.value])
        ?.map((airline: any) => (
          <FilterCheckboxItem
            key={airline.value}
            label={
              <>
                <OAAirlineLogo
                  airlineCode={airline?.value}
                  sx={{
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                  }}
                />
                <Typography variant='body2'>{airline.label}</Typography>
              </>
            }
            value={airline.value}
            checked={Boolean(localFilterData?.airlines?.includes(airline.value))}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeFilter(event, 'airlines')}
            price={airlinePrices[airline.value]}
          />
        ))}
      {data?.meta?.airlines?.length > 4 && (
        <Box
          mt='10px'
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          onClick={() => {
            setShowAllAirlines(!showAllAirlines);
          }}
        >
          <Typography
            variant='body2'
            color='#009E82'
            sx={{ cursor: 'pointer' }}
          >
            {showAllAirlines ? 'Show less' : `Show all`}
          </Typography>
          <OAImage
            src={!showAllAirlines ? 'drop-down.svg' : 'drop-up.svg'}
            alt='down'
            folder='icons'
            mr='8px'
          />
        </Box>
      )}
    </FilterSection>
  );

  const renderDepartureFilter = (title: string, filterType: string, from: string) => (
    <FilterSection title={`${title} from ${from}`} filterType={filterType} filterCount={getFilterCount(filterType)}>
      {data?.meta?.[filterType]?.map((item: any) => (
        <FilterCheckboxItem
          key={item.value}
          label={
            <Typography variant='body2' display='flex' alignItems='center'>
              <OAImage 
                src={
                  item.value === 'EARLY_MORNING' ? 'early-morning.svg' :
                  item.value === 'MORNING' ? 'morning.svg' :
                  item.value === 'MID_DAY' ? 'midday.svg' :
                  item.value === 'EVENING' ? 'evening.svg' :
                  item.value === 'NIGHT' ? 'night.svg' :
                  'early-morning.svg'
                } 
                alt='time' 
                folder='icons' 
                mr='10px' 
              />
              {item.label}
            </Typography>
          }
          value={item.value}
          checked={Boolean(localFilterData?.[filterType]?.includes(item.value))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeFilter(event, filterType)}
          time={item.info}
        />
      ))}
    </FilterSection>
  );

  const renderAirportsFilter = (title: string, filterType: string) => (
    <FilterSection title={title} filterType={filterType} filterCount={getFilterCount(filterType)}>
      {data?.meta?.[filterType]?.airports?.map((airport: any) => (
        <FilterCheckboxItem
          key={airport.iata}
          label={<Typography variant='body2'>{airport.name} ({airport.distance.label})</Typography>}
          value={airport.iata}
          checked={Boolean(localFilterData?.[filterType]?.includes(airport.iata))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeFilter(event, filterType)}

        />
      ))}
    </FilterSection>
  );

  return (
    <>
      {isLoading && <OALoadingOverlay />}
      <OAChip
        selected={totalFilter > 0}
        icon={<TuneIcon fontSize="small" sx={{ color: '#5A6068 !important' }} />}
        label={<FilterLabel label="Filter" total={totalFilter} />}
        onClick={() => toggleDrawer(true)}
        {...(totalFilter > 0 ? { onDelete: onClickClearFilter } : {})}
        {...(totalFilter > 0 ? { deleteIcon: <CloseIcon fontSize="small" sx={{ mr: 0 }} /> } : {})}
        disabled={data?.meta && data?.results?.onward?.length > 0 ? false : true}
      />
      <OASwipeableDrawer
        title={'Filters'}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        isContainer={false}
        height={95}
      >
        <Box>
          {data?.meta?.stops?.length > 0 && renderStopsFilter()}
          {data?.meta?.airlines?.length > 0 && (
            <>
              <Box px='20px'><Divider sx={{ my: '30px', borderStyle: 'dashed' }} /></Box>
              {renderAirlinesFilter()}
            </>
          )}
          {data?.meta?.onwardDeparture && (
            <>
              <Box px='20px'><Divider sx={{ my: '30px', borderStyle: 'dashed' }} /></Box>
              {renderDepartureFilter('Departure', 'onwardDeparture', from)}
            </>
          )}
          {data?.meta?.returnDeparture && !isInternationalReturn && (
            <>
              <Box px='20px'><Divider sx={{ my: '30px', borderStyle: 'dashed' }} /></Box>
              {renderDepartureFilter('Return', 'returnDeparture', to)}
            </>
          )}
          {data?.meta?.originNearbyAirports?.airports?.length > 0 && (
            <>
              <Box px='20px'><Divider sx={{ my: '30px', borderStyle: 'dashed' }} /></Box>
              {renderAirportsFilter('Departure airports', 'originNearbyAirports')}
            </>
          )}
          {data?.meta?.destinationNearbyAirports?.airports?.length > 0 && (
            <>
              <Box px='20px'><Divider sx={{ my: '30px', borderStyle: 'dashed' }} /></Box>
              {renderAirportsFilter('Arrival airports', 'destinationNearbyAirports')}
            </>
          )}
        </Box>
        <Container
          sx={{ position: 'sticky', zIndex: 10, bottom: 0, background: 'white', py: '30px', borderTop: '1px solid #DEE2E1' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <OAButton fullWidth disableRipple variant="outlined" color="secondary" onClick={handleClearBtn}>
                Clear Filter
              </OAButton>
            </Grid>
            <Grid item xs={6}>
              <OAButton onClick={handleApplyBtn} fullWidth variant="contained" color="secondary">
                Apply Filter
              </OAButton>
            </Grid>
          </Grid>
        </Container>
      </OASwipeableDrawer>
    </>
  );
};

export default Filter;