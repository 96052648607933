import { Box, Container, Skeleton, Typography } from '@mui/material';
import {
  OAButton,
  OACoinSwitch,
  OAFareSummary,
  OAFlightSummary,
  OAFooter,
  OAHeader,
  OAHeaderSubTitle,
  OAHeaderTitle,
  OAImage,
  OALoadingOverlay,
  OALoadingPayment,
  OARadioGroup,
  OASwipeableDrawer,
} from '../../components';
import List from '../Traveller/List';
import { useCallback, useEffect, useRef, useState } from 'react';
import Add from '../Traveller/Add';
import { ContactDetails } from '../Traveller/ContactDetails';
import { store, useDispatch, useSelector } from '../../store';
import {
  getFlightOrderState,
  getTotalBaggageCount,
  getTotalMealCount,
  getTotalSeatCount,
  getTotalSelectedSmbPrice,
  setError,
  setFlightData,
} from '../../store/slices/flightOrderSlice';
import { setMessage } from '../../store/slices/snackbarSlice';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { useNavigate } from 'react-router-dom';

import { getUserInfo } from '../../store/slices/userInfoSlice';
import {
  addAirlineLogo,
  getBurnCoin,
  getCurrentTabString,
  getDiscountPrice,
  getEarnCoin,
  getRsFromCoins,
  parseObject,
} from '../../utils';
import PriceCheckStatus from './PriceCheckStatus';
import {
  useGetTravellersQuery,
  useUpdateTravellersInFlightOrderMutation,
} from '../../services/travellersApi';
import { getAuthInfo } from '../../store/slices/authSlice';
import {
  useGetPaymentStatusMutation,
  useInitializePaymentMutation,
} from '../../services/paymentApi';
import { getSearchFlightState } from '../../store/slices/searchFlightSlice';
import SomethingWentWrong from './SomethingWentWrong';
import { PaymentStatus, setPaymentStatus, setOnGoingOrderId } from '../../store/slices/globalSlice';
import { useGetBookingsListQuery } from '../../services/bookingsApi';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { useCreateOrderMutation, useGetSMBQuery } from '../../services/createOrderApi';
import { usePostHog } from 'posthog-js/react';
import ErrorPaymentDone from './ErrorPaymentDone';
import { COIN_VALUE_IN_RS, MOCK_PAYMENT_STATUS } from '../../constants';
import { IFlight, ISegment } from '../../type';
import OAFlightRoute from '../../components/OAFlightRoute';
import SMB from './SMB';
import AddOnsSummary from '../../components/OASMB/components/AddOnsSummary';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import OAPaymentProcessed from '../../components/OAPaymentProcessed';
import OAPremiumMembership from '../../components/OAPremiumMembership';
import useKeyboardVisibility from '../../hooks/useKeyboardVisibility';

const getUserDetails = (users: any, ids: string[]) => {
  return users?.data
    ?.filter((user: any) => ids?.includes(user?.id))
    ?.map((user: any) => ({
      fullName: user?.fullName,
      age: user?.age,
    }));
};

type AnyObject = { [key: string]: any };

const removePrice = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(removePrice);
  } else if (data && typeof data === 'object') {
    const newObj: AnyObject = {};
    for (let key in data) {
      if (key !== 'price' && key !== 'displayDetail') {
        newObj[key] = removePrice(data[key]);
      }
    }
    return newObj;
  }
  return data;
};

const Review = () => {
  const posthog = usePostHog();
  const isKeyboardVisible = useKeyboardVisibility();
  const {
    flightData,
    smbData: selectedSmbData,
    error,
    isReviewAddOns,
  } = useSelector(getFlightOrderState);
  const { travelDetail, isCoinsApplied } = useSelector(getUserInputState);
  const { refetch: refetchBookingList } = useGetBookingsListQuery({});
  const { coins, isMock, tags, planDetails } = useSelector(getUserInfo);
  const { user } = useSelector(getAuthInfo);
  const { smbFeat, esimOffer, premiumUserFeat } = useSelector(getFeatureFlag);
  const [initializePayment] = useInitializePaymentMutation();
  const [getPaymentStatus] = useGetPaymentStatusMutation();
  const [
    updateTravellersInFlightOrder,
    { error: utifError, isLoading: utifIsLoading, isError: utifIsError },
  ] = useUpdateTravellersInFlightOrderMutation();
  const { selectedFlight } = useSelector(getSearchFlightState);
  const totalSelectedSmbPrice = useSelector(getTotalSelectedSmbPrice);

  const isPremiumUser =
    Array.isArray(tags) &&
    (tags?.includes('NIYO_GOLD_PREMIUM_USER') ||
      tags?.includes('NIYO_PREMIUM_USER') ||
      tags?.includes('NIYO_SILVER_PREMIUM_USER'));

  const {
    data: travellers,
    error: errorTraveller,
    isError: isErrorTraveller,
  } = useGetTravellersQuery({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [createOrder] = useCreateOrderMutation();

  const {
    data: smbDataFromApi,
    error: smbApiError,
    isLoading,
  } = useGetSMBQuery<any>(flightData?.id, {
    skip: !flightData?.id,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedTraveller, setSelectedTraveller] = useState(null);
  const [isConfirmItineraryOpen, setIsConfirmItineraryOpen] = useState<boolean>(false);
  const [selectedTravelers, setSelectedTravelers] = useState<any[]>([]);
  const [priceCheckStatusDrawer, setPriceCheckStatusDrawer] = useState<boolean>(false);
  const [priceCheckData, setPriceCheckData] = useState<any>(null);
  const [priceCheckSmb, setPriceCheckSmb] = useState<any>(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [isPaymentInitLoading, setIsPaymentInitLoading] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [isPaymentDone, setIsPaymentDone] = useState<boolean>(false);
  const [isRetryPayment, setIsRetryPayment] = useState<boolean>(false);
  const [transactionId, setTransactionId] = useState<string>('');
  const [timeOutInSeconds, setTimeOutInSeconds] = useState<number>(120);
  const [fieldErrors, setFieldErrors] = useState({});
  const [startTime, setStartTime] = useState(Date.now());
  const [mockPaymentStatus, setMockPaymentStatus] = useState<string | null>(null);
  const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
  const [isAirportReviewOpen, setIsAirportReviewOpen] = useState<boolean>(false);
  const [isFareUpdateAction, setIsFareUpdateAction] = useState<boolean>(false);
  const [airportReviewData, setAirportReviewData] = useState<any>(null);
  const [tabValue, setTabValue] = useState<number>(1);
  const [isSmbDrawerOpen, setIsSmbDrawerOpen] = useState<boolean>(false);
  const [pollCount, setPollCount] = useState<number>(0);
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);
  const isProcessingPaymentRef = useRef<boolean>(false);
  const [nearbyAirportNudges, setNearbyAirportNudges] = useState<{
    onward: { index: number; message: string }[];
    return: { index: number; message: string }[];
  }>({ onward: [], return: [] });
  const [isPremiumSelected, setIsPremiumSelected] = useState<boolean>(false);

  const maxPollAttempts = 7;

  const isInternational = flightData?.international;
  const flightUnavailableError =
    priceCheckData?.code === 532 ||
    priceCheckData?.code === 512 ||
    priceCheckSmb?.code === 512 ||
    priceCheckSmb?.code === 532;

  const [onwardSegments, setOnwardSegments] = useState<any[]>(
    selectedFlight?.onward?.segments.filter(
      (segment: ISegment) => segment.departureType === 'ONWARD'
    )
  );
  const [returnSegments, setReturnSegments] = useState<any[]>(
    isInternational
      ? selectedFlight?.onward?.segments.filter(
          (segment: ISegment) => segment.departureType === 'RETURN'
        )
      : selectedFlight?.return?.segments
  );
  const [onwardLayovers, setOnwardLayovers] = useState<any[]>(selectedFlight?.onward?.layovers);
  const [returnLayovers, setReturnLayovers] = useState<any[]>(
    isInternational ? selectedFlight?.onward?.layovers : selectedFlight?.return?.layovers
  );

  const premiumGoldDetails = planDetails?.data?.find((plan: any) => {
    const planName = plan?.planName?.toLowerCase();
    return planName === 'premium' || planName === 'gold';
  });

  useEffect(() => {
    if (flightData) {
      const nudges: any = { onward: [], return: [] };
      const searchedFrom = flightData?.flightSearchInfo?.from?.iata;
      const searchedTo = flightData?.flightSearchInfo?.to?.iata;

      flightData?.flights?.forEach((flight: any) => {
        const segments = flight?.segments;

        if (flight?.departureType === 'ONWARD') {
          if (segments[0]?.departure?.airport?.iata !== searchedFrom) {
            nudges?.onward?.push({
              index: 0,
              message: `The take-off airport is different from the one you searched for.`,
            });
          }
          if (segments[segments?.length - 1]?.arrival?.airport?.iata !== searchedTo) {
            nudges?.onward?.push({
              index: segments?.length - 1,
              message: `The landing airport is different from the one you searched for.`,
            });
          }
        } else if (flight?.departureType === 'RETURN') {
          if (segments[0]?.departure?.airport?.iata !== searchedTo) {
            nudges?.return?.push({
              index: 0,
              message: `The take-off airport is different from the one you searched for.`,
            });
          }
          if (segments[segments?.length - 1]?.arrival?.airport?.iata !== searchedFrom) {
            nudges?.return?.push({
              index: segments?.length - 1,
              message: `The landing airport is different from the one you searched for.`,
            });
          }
        }
      });

      setNearbyAirportNudges(nudges);
    }
  }, [flightData]);

  const [contactDetails, setContactDetails] = useState<any>({
    title: 'Mr',
    firstName: user?.firstName || 'Niyo',
    lastName: user?.lastName || 'User',
    dateOfBirth: '1985-04-23',
    nationality: 'IN',
    countryCode: 'IN',
    residentCountry: 'IN',
    email: user?.email || '',
    phoneNumber: user?.phoneNumber || '',
  });
  const [errors, setErrors] = useState({
    email: '',
    phoneNumber: '',
  });

  const travellersRef = useRef<any>(null);
  const contactDetailsRef = useRef<any>(null);
  const keyBoardRef = useRef<any>(null);

  useEffect(() => {
    if (isErrorTraveller && errorTraveller) setIsApiError(true);
  }, [isErrorTraveller, errorTraveller]);

  useEffect(() => {
    if (utifError && utifIsError) {
      const temp: any = { ...utifError };
      if (!temp?.data?.additionalData) {
        if (temp?.data?.errors?.length > 0) {
          const errorMsg = temp?.data?.errors?.map((obj: any) => obj?.message).join(', ');
          dispatch(setMessage(errorMsg));
        } else {
          dispatch(setMessage(temp?.data?.message));
        }
      }
    }
  }, [utifError, utifIsError]);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    setIsPaymentLoading(false);
    isProcessingPaymentRef.current = isProcessingPayment;
  }, [isProcessingPaymentRef]);

  const totalTravellers =
    (flightData?.travellerCount?.adult || travelDetail?.travellerCount?.adult) +
    (flightData?.travellerCount?.child || travelDetail?.travellerCount?.child) +
    (flightData?.travellerCount?.infant || travelDetail?.travellerCount?.infant);

  const onClickHandler = (isConfirmItinerary: boolean = false) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Confirm',
        ctaAction: 'Continue',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          from: flightData?.flightSearchInfo?.from,
          to: flightData?.flightSearchInfo?.to,
          departureDate: flightData?.flightSearchInfo?.tripStart,
          numberOfTravellers: totalTravellers,
          class: flightData?.flightSearchInfo?.cabinType,
          flightProvider: flightData?.flights?.[0]?.segments?.[0]?.airline?.name,
          destination: flightData?.international ? 'international' : 'domestic',
        },
      },
      posthog
    );
    const tCount = flightData?.travellerCount;
    const totalCount = (tCount?.adult ?? 0) + (tCount?.child ?? 0) + (tCount?.infant ?? 0);
    if (totalCount !== selectedTravelers?.length) {
      travellersRef.current?.scrollIntoView({ behavior: 'smooth' });
      dispatch(setMessage('Please select travellers'));
      return;
    } else if (!contactDetails?.email || !contactDetails?.phoneNumber) {
      contactDetailsRef.current?.scrollIntoView({ behavior: 'smooth', top: 0, block: 'start' });
      dispatch(setMessage('Please enter contact details'));
      return;
    } else if (errors?.email !== '' || errors?.phoneNumber !== '') {
      dispatch(setMessage('Please enter valid contact information'));
    } else {
      const phoneNumber = `+91${contactDetails?.phoneNumber}`;
      const draft: any = {
        travellerIds: selectedTravelers,
        contactdetails: { ...contactDetails, phoneNumber },
        orderId: flightData?.id,
        userSelectedJourneyAncillaries: removePrice(
          selectedSmbData?.userSelectedJourneyAncillaries
        ),
        ...(priceCheckSmb && { updatedFare: true }),
      };
      setIsFareUpdateAction(true);
      updateTravellersInFlightOrder(draft).then((res: any) => {
        if (res?.data?.httpStatus === 200) {
          dispatch(setFlightData(res?.data?.data));
          if (isConfirmItinerary === true) {
            // setIsReviewAddOnDrawerOpen(false);
            setIsConfirmItineraryOpen(true);
            // } else if (isReviewAddOns) {
            //   // setIsReviewAddOnDrawerOpen(true);
            //
            setIsFareUpdateAction(false);
          } else {
            setIsConfirmItineraryOpen(true);
            setIsFareUpdateAction(false);
          }

          // if (isReviewAddOnsParam === true) {
          //   setIsReviewAddOnDrawerOpen(false);
          //   setIsConfirmItineraryOpen(true);
          // } else {
          //   if (isReviewAddOns) toggleReviewAddOnDrawer();
          //   else setIsConfirmItineraryOpen(true);
          // }
        } else if (res?.error) {
          setIsFareUpdateAction(false);
          const errorMessage = res?.error?.data?.additionalData;
          if (errorMessage) {
            setPriceCheckSmb(errorMessage);
            setPriceCheckStatusDrawer(true);
          } else {
            if (error?.status !== 401) setIsApiError(true);
          }
        }
      });
    }
  };

  const toggleDrawer = () => setIsOpen(!isOpen);
  const toggleDrawerToEdit = () => setIsOpen(!isOpen);

  const toggleItineraryDrawer = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    const totalTravelers =
      (travelDetail?.travellerCount?.adult || 0) +
      (travelDetail?.travellerCount?.child || 0) +
      (travelDetail?.travellerCount?.infant || 0);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'PayConfirm',
        ctaAction: 'Close',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          from: travelDetail?.from?.iata,
          to: travelDetail?.to?.iata,
          departureDate: travelDetail?.tripStart?.date,
          class: travelDetail?.cabinType,
          numberOfTravellers: totalTravelers,
          destination: flightData?.international ? 'international' : 'domestic',
          totalAmount: flightData?.orderAmount?.totalAmount,
        },
      },
      posthog
    );
    setIsConfirmItineraryOpen(!isConfirmItineraryOpen);
  };
  const togglePriceCheckDrawer = () => setPriceCheckStatusDrawer(true);

  const userDetails = getUserDetails(travellers, selectedTravelers);

  useEffect(() => {
    // Assign paymentCallBack to window.oa_payment_callback when the component mounts
    window.oa_payment_callback = (pgResponse: any) => {
      const globalState = store.getState();
      paymentCallBack(pgResponse, globalState.global.onGoingOrderId, transactionId);
    };
  }, []);

  const paymentFailedHandle = () => {
    const totalTravelers =
      (travelDetail?.travellerCount?.adult || 0) +
      (travelDetail?.travellerCount?.child || 0) +
      (travelDetail?.travellerCount?.infant || 0);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'PayConfirm',
        ctaAction: 'Failed',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          from: travelDetail?.from?.iata,
          to: travelDetail?.to?.iata,
          departureDate: travelDetail?.tripStart?.date,
          class: travelDetail?.cabinType,
          numberOfTravellers: totalTravelers,
          destination: flightData?.international ? 'international' : 'domestic',
          totalAmount: flightData?.orderAmount?.totalAmount,
        },
      },
      posthog
    );
    setIsPaymentLoading(false);
    setIsRetryPayment(true);
    dispatch(setPaymentStatus(PaymentStatus.Failed));
    dispatch(setMessage('Payment failed. Please try again'));
    setIsProcessingPayment(false);
    isProcessingPaymentRef.current = false;
  };

  const pollPaymentStatus = useCallback(
    async (orderId: string, transactionIdP: string, currentPollCount = 1) => {
      const totalScreenTime = Math.floor((Date.now() - startTime) / 1000);
      if (currentPollCount >= maxPollAttempts) {
        setIsPaymentLoading(false);
        paymentFailedHandle();
        return;
      }

      try {
        const response: any = await getPaymentStatus({ orderId });
        const responseData = response?.data;

        if (responseData?.data?.status === 'PAYMENT_SUCCESSFUL') {
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'PayConfirm',
              ctaAction: 'Success',
              screenDuration: totalScreenDuration?.toString(),
              UserAction: {
                userAction: 'user has completed payment successfully',
              },
            },
            posthog
          );
          refetchBookingList();
          dispatch(setPaymentStatus(PaymentStatus.Succeeded));
          navigate(`/booking-status/${orderId}?review`);
          setIsProcessingPayment(false);
          isProcessingPaymentRef.current = false;
        } else if (responseData?.data?.status === 'PAYMENT_FAILED') {
          paymentFailedHandle();
        } else {
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'Loader',
              ctaAction: 'Status',
              screenDuration: totalScreenTime?.toString(),
            },
            posthog
          );
          // scheduling next poll with exponential backoff
          const nextPollDelay = Math.min(Math.pow(2, currentPollCount) * 1000, 30000); // 2^n seconds
          setTimeout(() => {
            setPollCount(currentPollCount + 1);
            pollPaymentStatus(orderId, transactionIdP, currentPollCount + 1);
          }, nextPollDelay);
        }
      } catch (error) {
        console.error('Error polling payment status:', error);
        paymentFailedHandle();
      }
    },
    [
      pollCount,
      maxPollAttempts,
      getPaymentStatus,
      eventsTracker,
      refetchBookingList,
      dispatch,
      navigate,
      paymentFailedHandle,
      totalScreenDuration,
      posthog,
    ]
  );

  const paymentCallBack = async (pgCallbackRes: any, orderId: string, transactionIdP: string) => {
    try {
      setIsProcessingPayment(true);
      setIsPaymentLoading(false);
      isProcessingPaymentRef.current = true;

      const pgParse = parseObject(pgCallbackRes);
      console.log('pgParse', pgParse);

      if (!pgParse?.status || !orderId) {
        setIsApiError(true);
        return;
      }

      if (pgParse.status === 'NO_PAYMENT_MODES_AVAILABLE') {
        setIsPaymentLoading(false);
        dispatch(setMessage('Unable to make payment. No UPI app was found'));
        return;
      }

      if (
        ['CANCELLED', 'CANCELED_BEFORE_INITIATING_PAYMENT', 'VALIDATION_FAILED']?.includes(
          pgParse.status
        )
      ) {
        eventsTracker(
          {
            flowName: 'Flight',
            screenName: 'PayError',
            ctaAction: 'BurnRetry',
            screenDuration: totalScreenDuration?.toString(),
          },
          posthog
        );

        if (pgParse.status === 'VALIDATION_FAILED') {
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'PG',
              ctaAction: 'ValidationFailed',
              screenDuration: totalScreenDuration?.toString(),
              UserAction: {
                userAction: pgParse,
              },
            },
            posthog
          );
        }

        setIsPaymentLoading(false);
        setIsRetryPayment(true);
        setIsProcessingPayment(false);
        isProcessingPaymentRef.current = false;
        return;
      }

      let statusReq: any = {
        orderId: orderId,
        ...(!window.flutter_inappwebview ? { paymentStatus: pgParse?.status } : {}),
      };

      try {
        const response: any = await getPaymentStatus(statusReq);
        const responseData = response?.data;

        if (!responseData || responseData?.httpStatus !== 200) {
          paymentFailedHandle();
          return;
        }

        if (responseData?.data?.status === 'PAYMENT_SUCCESSFUL') {
          const totalTravelers =
            (travelDetail?.travellerCount?.adult || 0) +
            (travelDetail?.travellerCount?.child || 0) +
            (travelDetail?.travellerCount?.infant || 0);
          eventsTracker(
            {
              flowName: 'Flight',
              screenName: 'PayConfirm',
              ctaAction: 'Success',
              screenDuration: totalScreenDuration?.toString(),
              otherData: {
                from: travelDetail?.from?.iata,
                to: travelDetail?.to?.iata,
                departureDate: travelDetail?.tripStart?.date,
                class: travelDetail?.cabinType,
                numberOfTravellers: totalTravelers,
                destination: flightData?.international ? 'international' : 'domestic',
                totalAmount: flightData?.orderAmount?.totalAmount,
              },
            },
            posthog
          );
          refetchBookingList();
          dispatch(setPaymentStatus(PaymentStatus.Succeeded));
          navigate(`/booking-status/${orderId}?review`);
        } else if (
          responseData?.data?.status === 'PAYMENT_INITIATED' ||
          responseData?.data?.status === 'PAYMENT_PROCRESSING'
        ) {
          // waiting for 5 seconds before initiating polling
          setTimeout(() => {
            setPollCount(0);
            pollPaymentStatus(orderId, transactionIdP);
          }, 5000);
        } else {
          paymentFailedHandle();
        }
      } catch (error) {
        setIsProcessingPayment(false);
        isProcessingPaymentRef.current = false;
        paymentFailedHandle();
      }
    } catch (error) {
      setIsProcessingPayment(false);
      isProcessingPaymentRef.current = false;
      setIsApiError(true);
    }
  };

  const seatCount = useSelector(getTotalSeatCount);
  const mealCount = useSelector(getTotalMealCount);
  const baggageCount = useSelector(getTotalBaggageCount);

  const prevSeatCount =
    flightData &&
    getTotalSeatCount({ userSelectedJourneyAncillaries: flightData?.selectedJourneyAncillaries });
  const prevMealCount =
    flightData &&
    getTotalMealCount({ userSelectedJourneyAncillaries: flightData?.selectedJourneyAncillaries });
  const prevBaggageCount =
    flightData &&
    getTotalBaggageCount({
      userSelectedJourneyAncillaries: flightData?.selectedJourneyAncillaries,
    });

  const handleSubmitOrder = async (updatedFlightData = flightData) => {
    const totalTravelers =
      (travelDetail?.travellerCount?.adult || 0) +
      (travelDetail?.travellerCount?.child || 0) +
      (travelDetail?.travellerCount?.infant || 0);
    if (isRetryPayment) {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'PayConfirm',
          ctaAction: 'Retry',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            from: travelDetail?.from?.iata,
            to: travelDetail?.to?.iata,
            departureDate: travelDetail?.tripStart?.date,
            class: travelDetail?.cabinType,
            numberOfTravellers: totalTravelers,
            destination: flightData?.international ? 'international' : 'domestic',
            totalAmount: flightData?.orderAmount?.totalAmount,
          },
        },
        posthog
      );
    } else {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'PayConfirm',
          ctaAction: 'Pay',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            from: travelDetail?.from?.iata,
            to: travelDetail?.to?.iata,
            departureDate: travelDetail?.tripStart?.date,
            class: travelDetail?.cabinType,
            numberOfTravellers: totalTravelers,
            destination: flightData?.international ? 'international' : 'domestic',
            totalAmount: flightData?.orderAmount?.totalAmount,
          },
        },
        posthog
      );
    }
    setPriceCheckStatusDrawer(false);
    setIsRetryPayment(false);
    setIsPaymentInitLoading(false);
    setIsPaymentLoading(false);
    setIsApiError(false);
    setIsPaymentDone(false);
    // setIsReviewAddOnDrawerOpen(false);

    const tCount = flightData?.travellerCount;
    const totalCount = (tCount?.adult ?? 0) + (tCount?.child ?? 0) + (tCount?.infant ?? 0);

    if (!contactDetails?.email || !contactDetails?.phoneNumber) {
      dispatch(setMessage('Please enter contact details'));
      return;
    }

    if (!selectedTravelers?.length || totalCount !== selectedTravelers?.length) {
      dispatch(setMessage('Please select travellers'));
      return;
    }

    if (!flightData?.id || !flightData?.orderAmount) {
      setIsApiError(true);
      dispatch(setMessage('Please check flight data'));
      return;
    }

    try {
      let updatedFlightDataToUse = updatedFlightData;
      if (
        prevSeatCount !== seatCount ||
        prevMealCount !== mealCount ||
        prevBaggageCount !== baggageCount
      ) {
        // updating traveller information with new SMB data
        const phoneNumber = `+91${contactDetails?.phoneNumber}`;
        const draft: any = {
          travellerIds: selectedTravelers,
          contactdetails: { ...contactDetails, phoneNumber },
          orderId: flightData?.id,
          userSelectedJourneyAncillaries: removePrice(
            selectedSmbData?.userSelectedJourneyAncillaries
          ),
          ...(priceCheckSmb && { updatedFare: true }),
        };

        const updateTravellerResponse: any = await updateTravellersInFlightOrder(draft).unwrap();

        if (updateTravellerResponse?.httpStatus !== 200) {
          setIsApiError(true);
          dispatch(setMessage('Failed to update traveller information'));
        } else if (updateTravellerResponse?.error) {
          setIsFareUpdateAction(false);
          //handling price check error
          const errorMessage = updateTravellerResponse?.error?.data?.additionalData;
          if (errorMessage) {
            setPriceCheckSmb(errorMessage);
            setPriceCheckStatusDrawer(true);
          } else {
            if (updateTravellerResponse?.error?.status !== 401) setIsApiError(true);
          }
        }

        updatedFlightDataToUse = updateTravellerResponse?.data;
        dispatch(setFlightData(updatedFlightDataToUse));
      }

      const totalAmount =
        updatedFlightDataToUse?.orderAmount?.totalAmount ?? flightData?.orderAmount?.totalAmount;
      const discountPrice = getDiscountPrice(totalAmount, coins);
      const burnCoin = getBurnCoin(totalAmount, coins);
      const earnCoin = getEarnCoin(totalAmount);
      const amountInCoins = getRsFromCoins(burnCoin ?? 0);
      const initPaymentRequest = {
        orderId: flightData?.id,
        amountInCash: isCoinsApplied ? discountPrice : totalAmount,
        coinsRedeemed: isCoinsApplied ? amountInCoins * COIN_VALUE_IN_RS : 0,
        amountInCoins: isCoinsApplied ? amountInCoins : 0,
        coinsEarned: earnCoin,
        earnedAmountInCoins: earnCoin > 0 ? earnCoin / COIN_VALUE_IN_RS : 0,
        totalAmount: totalAmount,
        ...(isPremiumSelected && {
          addOnServices: [
            {
              planName: premiumGoldDetails?.planName,
              planId: premiumGoldDetails?._id,
              type: 'NIYO_PREMIUM_PLAN',
              amount: premiumGoldDetails?.planAmount?.totalAmount,
            },
          ],
        }),
      };

      setIsPaymentInitLoading(true);
      const initPaymentResponse: any = await initializePayment(initPaymentRequest);

      if (initPaymentResponse?.error?.data?.errorCode === 'PG_001') {
        setIsPaymentInitLoading(false);
        setIsPaymentDone(true);
        return;
      }

      const resData = initPaymentResponse?.data?.data;

      // Handle price check status
      if (resData?.priceCheckStatus && [532, 533].includes(resData?.priceCheckStatus?.code)) {
        setIsPaymentInitLoading(false);
        handlePriceCheckStatus(resData?.priceCheckStatus);
        return;
      }

      if (
        !resData ||
        !resData?.priceCheckStatus ||
        !resData?.paymentSummary ||
        !resData?.paymentSummary?.paymentGatewayMetaInfo
      ) {
        setIsPaymentInitLoading(false);
        setIsApiError(true);
        return;
      }

      const { paymentSummary } = resData;

      const { paymentGatewayMetaInfo, id } = paymentSummary;
      setTimeOutInSeconds(paymentGatewayMetaInfo?.timeOutInSeconds ?? 120);
      setTransactionId(id);
      dispatch(setPaymentStatus(PaymentStatus.InProgress));
      dispatch(setOnGoingOrderId(paymentSummary?.orderId));
      setIsPaymentInitLoading(false);
      setIsPaymentLoading(true);
      setIsFareUpdateAction(false);

      if (window.flutter_inappwebview && typeof window.niyo_paymentGateway === 'function') {
        const paymentGatewayMetaInfoTemp = { ...paymentGatewayMetaInfo };
        if (isMock) {
          paymentGatewayMetaInfoTemp.mockResponse = mockPaymentStatus;
        }
        // added otherData
        const updatedFlightData = addAirlineLogo(flightData);
        paymentGatewayMetaInfoTemp.otherData = updatedFlightData;
        const paymentGatewayRequest = JSON.stringify(paymentGatewayMetaInfoTemp);
        window.niyo_paymentGateway(paymentGatewayRequest).then((pgResponse) => {
          paymentCallBack(pgResponse, paymentSummary?.orderId, id);
        });
      }
    } catch (error) {
      setIsPaymentLoading(false);
      setIsApiError(true);
    }
  };

  // Handle price check status function
  const handlePriceCheckStatus = (status: { code: number }) => {
    toggleItineraryDrawer();
    setPriceCheckStatusDrawer(true);
    setPriceCheckData(status);
    setIsPaymentLoading(false);
  };

  useEffect(() => {
    if (error) {
      const errorMessage = error?.data?.additionalData;
      if (errorMessage) {
        setPriceCheckData(errorMessage);
        setPriceCheckStatusDrawer(true);
      } else {
        if (error?.status !== 401) setIsApiError(true);
      }
    }
  }, [error]);

  const handleCreateOrderWithNewPrice = async () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    setIsFareUpdateAction(true);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'FareUpdate',
        ctaAction: 'Continue',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    if (priceCheckData?.newTotalAmount && Object.keys(flightData)?.length !== 0) {
      const updatedFlightData = {
        ...selectedFlight,
        orderAmount: {
          ...flightData?.orderAmount,
          totalAmount: priceCheckData?.newTotalAmount,
        },
      };
      await handleSubmitOrder(updatedFlightData);
      dispatch(setError(null));
    } else {
      const updatedFlightData = {
        onwardFlight: selectedFlight?.onward,
        searchId: selectedFlight?.searchId,
        updatedFare: true,
        ...(selectedFlight?.international && travelDetail?.tripType === 'R'
          ? { returnFlight: selectedFlight?.onward }
          : selectedFlight?.return &&
            Object.keys(selectedFlight?.return).length > 0 && {
              returnFlight: selectedFlight?.return,
            }),
      };
      await createOrder(updatedFlightData)
        .then((res: any) => {
          if (res?.error) {
            dispatch(setError(res?.error));
            setIsFareUpdateAction(false);
            // dispatch(setMessage('Order creation failed'));
          } else {
            setPriceCheckStatusDrawer(false);
            dispatch(setError(null));
            dispatch(setFlightData(res?.data?.data));
            setPriceCheckData(null);
            setIsFareUpdateAction(false);
          }
        })
        .catch((error: any) => {
          console.error('An error occurred:', error);
        });
    }
  };

  const toggleApiError = () => setIsApiError(true);

  const getCurrentSelectionCounts = () => {
    const counts: any = {
      ADULT: 0,
      CHILD: 0,
      INFANT: 0,
    };

    selectedTravelers?.forEach((id) => {
      const foundTraveller: any = travellers?.data?.find((traveller: any) => traveller?.id === id);
      if (foundTraveller) {
        counts[foundTraveller?.type] += 1;
      }
    });
    return counts;
  };

  const onNewTravellerAdded = (newTravellerId: string, newTravellerType: any) => {
    const allowedTypes: any = {
      adult: flightData?.travellerCount?.adult || travelDetail?.travellerCount?.adult,
      child: flightData?.travellerCount?.child || travelDetail?.travellerCount?.child,
      infant: flightData?.travellerCount?.infant || travelDetail?.travellerCount?.infant,
    };

    const currentSelectionCounts = getCurrentSelectionCounts();

    if (
      allowedTypes[newTravellerType?.toLowerCase()] &&
      currentSelectionCounts[newTravellerType?.toUpperCase()] <
        allowedTypes[newTravellerType?.toLowerCase()]
    ) {
      setSelectedTravelers((prevSelectedTravelers) => [...prevSelectedTravelers, newTravellerId]);
    }
  };

  useEffect(() => {
    let onwardSegments: any = [];
    let returnSegments: any = [];
    let onwardLayovers: any = [];
    let returnLayovers: any = [];

    if (flightData?.flights) {
      flightData?.flights?.forEach((flight: IFlight) => {
        if (flight?.departureType === 'ONWARD') {
          onwardSegments = flight?.segments;
          onwardLayovers = flight?.layovers;
        } else if (flight.departureType === 'RETURN') {
          returnSegments = flight?.segments;
          returnLayovers = flight?.layovers;
        }
      });

      setOnwardSegments(onwardSegments);
      setReturnSegments(returnSegments);
      setOnwardLayovers(onwardLayovers);
      setReturnLayovers(returnLayovers);
    }
  }, [flightData?.flights]);

  // NOTE: temp added mock payment status
  const handleMockPaymentStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMockPaymentStatus(event.target.value);
  };

  useEffect(() => {
    document.body.style.overflow = '';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const isDifferentAirport = useCallback(() => {
    const onwardFlights = flightData?.flights?.find(
      (flight: any) => flight?.departureType === 'ONWARD'
    );
    const onwardFlightsLength = onwardFlights?.segments?.length;
    const returnFlights = flightData?.flights?.find(
      (flight: any) => flight?.departureType === 'RETURN'
    );
    const returnFlightsLength = returnFlights?.segments?.length;

    const onwardDeparture = onwardFlights?.segments?.[0]?.departure?.airport?.iata;
    const onwardArrival =
      onwardFlights?.segments?.[onwardFlightsLength - 1]?.arrival?.airport?.iata;

    const returnDeparture = returnFlights?.segments?.[0]?.departure?.airport?.iata;
    const returnArrival =
      returnFlights?.segments?.[returnFlightsLength - 1]?.arrival?.airport?.iata;

    const searchedFrom = flightData?.flightSearchInfo?.from?.iata;
    const searchedTo = flightData?.flightSearchInfo?.to?.iata;
    const isDifferentOnward = onwardDeparture !== searchedFrom || onwardArrival !== searchedTo;
    const isDifferentReturn = returnDeparture !== searchedTo || returnArrival !== searchedFrom;

    return {
      isDifferentOnward,
      isDifferentReturn,
      onwardDeparture,
      onwardArrival,
      returnDeparture,
      returnArrival,
      searchedFrom,
      searchedTo,
    };
  }, [flightData]);

  useEffect(() => {
    const checkAirportDifference = () => {
      const {
        isDifferentOnward,
        isDifferentReturn,
        onwardDeparture,
        onwardArrival,
        returnDeparture,
        returnArrival,
        searchedFrom,
        searchedTo,
      } = isDifferentAirport();
      if (isDifferentOnward || (isDifferentReturn && travelDetail?.tripType === 'R')) {
        setIsAirportReviewOpen(true);
        setAirportReviewData({
          isDifferentOnward,
          isDifferentReturn,
          onwardDeparture,
          onwardArrival,
          returnDeparture,
          returnArrival,
          searchedFrom,
          searchedTo,
          tripType: travelDetail?.tripType,
        });
      }
    };

    try {
      checkAirportDifference();
    } catch (error) {
      console.error('An error occurred while checking airport information.');
    }
  }, [flightData, isDifferentAirport, travelDetail]);

  useEffect(() => {
    if (airportReviewData && selectedFlight?.nearbyAirports) {
      const {
        isDifferentOnward,
        isDifferentReturn,
        onwardDeparture,
        onwardArrival,
        returnDeparture,
        returnArrival,
        searchedFrom,
        searchedTo,
        tripType,
      } = airportReviewData;

      const nearbyAirports = selectedFlight.nearbyAirports || [];

      let newNearbyAirportNudges: {
        onward: { index: number; message: any }[];
        return: { index: number; message: any }[];
      } = {
        onward: [],
        return: [],
      };

      if (isDifferentOnward) {
        if (onwardDeparture && searchedFrom && onwardDeparture !== searchedFrom) {
          const departureDiff = nearbyAirports?.origin?.airports?.find(
            (airport: any) => airport.iata === onwardDeparture
          );
          newNearbyAirportNudges.onward.push({
            index: 0,
            message: (
              <>
                Take-off airport is <strong>{departureDiff?.distance?.label || ''}</strong> away
                from {searchedFrom}
              </>
            ),
          });
        }
        if (onwardArrival && searchedTo && onwardArrival !== searchedTo) {
          const arrivalDiff = nearbyAirports?.destination?.airports?.find(
            (airport: any) => airport.iata === onwardArrival
          );
          newNearbyAirportNudges.onward.push({
            index: onwardSegments?.length - 1,
            message: (
              <>
                Landing airport is <strong>{arrivalDiff?.distance?.label || ''}</strong> away from{' '}
                {searchedTo}
              </>
            ),
          });
        }
      }

      if (isDifferentReturn && tripType === 'R') {
        if (returnDeparture && searchedTo && returnDeparture !== searchedTo) {
          const departureDiff = nearbyAirports?.destination?.airports?.find(
            (airport: any) => airport.iata === returnDeparture
          );
          newNearbyAirportNudges.return.push({
            index: 0,
            message: (
              <>
                Take-off airport is <strong>{departureDiff?.distance?.label || ''}</strong> away
                from {searchedTo}
              </>
            ),
          });
        }
        if (returnArrival && searchedFrom && returnArrival !== searchedFrom) {
          const arrivalDiff = nearbyAirports?.origin?.airports?.find(
            (airport: any) => airport.iata === returnArrival
          );
          newNearbyAirportNudges.return.push({
            index: returnSegments.length - 1,
            message: (
              <>
                Landing airport is <strong>{arrivalDiff?.distance?.label || ''}</strong> away from{' '}
                {searchedFrom}
              </>
            ),
          });
        }
      }

      setNearbyAirportNudges(newNearbyAirportNudges);
    }
  }, [airportReviewData, selectedFlight, onwardSegments, returnSegments]);

  const totalSelectedFlightPrice =
    (selectedFlight?.onward?.price?.value || 0) + (selectedFlight?.return?.price?.value || 0);

  const orderAmountIncludingSmb =
    (flightData?.orderAmount?.totalAmount || 0) -
    (flightData?.orderAmount?.seatCharges || 0) -
    (flightData?.orderAmount?.mealCharges || 0) -
    (flightData?.orderAmount?.baggageCharges || 0) +
    (totalSelectedSmbPrice || 0);

  //passing this in SMB
  const discountPrice = getDiscountPrice(orderAmountIncludingSmb, coins);

  const onwardDurationInfo = selectedFlight?.onward?.durationInfo?.display;
  const returnDurationInfo =
    selectedFlight?.onward?.returnDurationInfo?.display ??
    selectedFlight?.return?.returnDurationInfo?.display;

  // const toggleReviewAddOnDrawer = () => setIsReviewAddOnDrawerOpen((prev: boolean) => !prev);

  const totalSegments = (onwardSegments?.length || 0) + (returnSegments?.length || 0);

  const toggleSmbDrawer = (currentTab?: number) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    if (typeof currentTab === 'number' && !isNaN(currentTab)) {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'Itinerary',
          ctaAction: getCurrentTabString(currentTab),
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    } else {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SMB',
          ctaAction: 'Close',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            tab: getCurrentTabString(tabValue),
          },
        },
        posthog
      );
    }
    // if (!data) return;
    if (selectedTravelers?.length !== totalTravellers) {
      travellersRef.current?.scrollIntoView({ behavior: 'smooth' });
      dispatch(setMessage('Please select travellers'));
    } else {
      if (currentTab) {
        setTabValue(currentTab);
      }
      setIsSmbDrawerOpen(!isSmbDrawerOpen);
    }
    // if (selectedTravelers !== totalTravellers) {
    //   scrollToTravellers?.scrollIntoView({ behavior: 'smooth' });
    //   dispatch(setMessage('Please select travellers'));
    // } else {
    // setIsReviewAddOnDrawerOpen(false);

    // }
  };

  const seatRows =
    smbDataFromApi?.data?.[0]?.journeyAncillaries?.[0]?.segmentAncillaries?.[0]?.seatAncillaries
      ?.aircraftLayout?.lowerDeck?.compartments?.[0]?.rows;
  const mealsArray =
    smbDataFromApi?.data?.[0]?.journeyAncillaries?.[0]?.segmentAncillaries?.[0]?.mealAncillaries
      ?.mealInfos;
  const baggageArray =
    smbDataFromApi?.data?.[0]?.journeyAncillaries?.[0]?.baggageAncillary?.baggageDetails?.[0]
      ?.baggageInfos;

  const onwardFromAndTo =
    onwardSegments?.length > 0
      ? {
          from: onwardSegments?.[0]?.departure?.airport?.city,
          to: onwardSegments[onwardSegments?.length - 1].arrival?.airport?.city,
        }
      : null;

  const returnFromAndTo =
    returnSegments?.length > 0
      ? {
          from: returnSegments[0].departure.airport.city,
          to: returnSegments[returnSegments?.length - 1]?.arrival?.airport?.city,
        }
      : null;

  useEffect(() => {
    if (isKeyboardVisible && keyBoardRef) {
      setTimeout(() => {
        // focusedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        keyBoardRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    }
  }, [isKeyboardVisible, keyBoardRef]);
  return (
    <>
      {(isPaymentInitLoading || utifIsLoading) && <OALoadingOverlay />}
      {isPaymentLoading && (
        <OALoadingPayment
          setIsPaymentLoading={setIsPaymentLoading}
          setIsRetryPayment={setIsRetryPayment}
          transactionId={transactionId}
          timeOutInSeconds={timeOutInSeconds + 300}
        />
      )}
      {(isProcessingPaymentRef.current || pollCount > 1 || isProcessingPayment) && (
        <OAPaymentProcessed timeOutInSeconds={timeOutInSeconds + 300} />
      )}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        }}
      >
        <OAHeader
          title={<OAHeaderTitle travelDetail={travelDetail} />}
          subTitle={<OAHeaderSubTitle travelDetail={travelDetail} />}
        />
        <OACoinSwitch isReviewPage />
      </Box>
      <Box sx={{ bgcolor: '#F4F6F5', pt: '13px', pb: '16px' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          color="gray"
          bgcolor="#F4F6F5"
          p="10px 20px"
        >
          <OAFlightRoute
            from={onwardFromAndTo?.from}
            to={onwardFromAndTo?.to}
            icon="flight-take-off.svg"
          />
          <Box>
            {flightData?.flights?.[0]?.minirule ? (
              <Typography
                variant="body3"
                p="5px 10px"
                fontWeight={600}
                borderRadius="4px"
                bgcolor={flightData?.flights?.[0]?.minirule?.refundable ? '#DBF5ED' : '#DEE2E1'}
                color={flightData?.flights?.[0]?.minirule?.refundable ? '#194244' : '#5A6068'}
                sx={{ borderRadius: '46px' }}
              >
                {flightData?.flights?.[0]?.minirule?.refundable
                  ? 'Part-Refundable'
                  : 'Non-refundable'}
              </Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box>
        </Box>
        {onwardSegments?.map((segment: ISegment, index: number) => (
          <OAFlightSummary
            key={segment.segmentId}
            segmentIndex={index}
            selectedFlight={flightData}
            layovers={onwardLayovers}
            tripType={travelDetail?.tripType}
            data={segment}
            nearbyAirportNudge={
              nearbyAirportNudges?.onward?.find((nudge) => nudge?.index === index)?.message
            }
          />
        ))}
        {returnSegments?.length > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            color="gray"
            bgcolor="#F4F6F5"
            p="10px 20px"
            mt="15px"
          >
            <OAFlightRoute
              from={returnFromAndTo?.from}
              to={returnFromAndTo?.to}
              icon="flight-land.svg"
            />
            <Box>
              {flightData?.flights?.[1]?.minirule ? (
                <Typography
                  variant="body3"
                  p="5px 10px"
                  fontWeight={600}
                  borderRadius="4px"
                  bgcolor={flightData?.flights?.[1]?.minirule?.refundable ? '#DBF5ED' : '#DEE2E1'}
                  color={flightData?.flights?.[1]?.minirule?.refundable ? '#194244' : '#5A6068'}
                  sx={{ borderRadius: '46px' }}
                >
                  {flightData?.flights?.[1]?.minirule?.refundable
                    ? 'Part-Refundable'
                    : 'Non-refundable'}
                </Typography>
              ) : (
                <Skeleton width="100px" />
              )}
            </Box>
          </Box>
        )}

        {returnSegments?.length > 0 &&
          [...returnSegments]
            ?.sort((a: ISegment, b: ISegment) => a?.segmentId - b?.segmentId)
            ?.map((segment: ISegment, index: number) => (
              <OAFlightSummary
                key={segment.segmentId}
                segmentIndex={index}
                selectedFlight={flightData}
                layovers={returnLayovers}
                tripType={travelDetail?.tripType}
                data={segment}
                nearbyAirportNudge={
                  nearbyAirportNudges?.return?.find((nudge) => nudge?.index === index)?.message
                }
              />
            ))}
      </Box>
      <Box ref={travellersRef}>
        <List
          selectedTravelers={selectedTravelers}
          setSelectedTravelers={setSelectedTravelers}
          toggleDrawer={toggleDrawerToEdit}
          setSelectedTraveller={setSelectedTraveller}
          setFieldErrors={setFieldErrors}
        />
      </Box>
      <OASwipeableDrawer
        title={`${selectedTraveller ? 'Edit' : 'Add'} Traveller`}
        open={isOpen}
        onOpen={toggleDrawer}
        onClose={() => {
          setSelectedTraveller(null);
          toggleDrawer();
        }}
      >
        <Add
          traveller={selectedTraveller}
          setSelectedTraveller={setSelectedTraveller}
          closeDrawer={setIsOpen}
          onNewTravellerAdded={onNewTravellerAdded}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
        />
      </OASwipeableDrawer>
      <Box ref={contactDetailsRef}>
        <ContactDetails
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
          errors={errors}
          setErrors={setErrors}
        />
      </Box>

      <Box ref={keyBoardRef}></Box>

      {smbFeat && !isKeyboardVisible && (
        <Box>
          {' '}
          <Box height={74}></Box>
          <Box
            position="fixed"
            bottom={isPremiumUser ? '91px' : '75px'}
            width="100%"
            zIndex={10}
            borderTop="1px solid #DEE2E1"
          >
            <SMB
              data={smbDataFromApi}
              isLoading={isLoading}
              ancillaryId={flightData?.id}
              totalTravellers={totalTravellers}
              isDiscountApplied={isCoinsApplied}
              totalSelectedFlightPrice={orderAmountIncludingSmb}
              discountPrice={discountPrice}
              tabValue={tabValue}
              setTabValue={setTabValue}
              isSmbDrawerOpen={isSmbDrawerOpen}
              setIsSmbDrawerOpen={setIsSmbDrawerOpen}
              toggleSmbDrawer={toggleSmbDrawer}
              onClickHandler={onClickHandler}
              selectedTravelers={selectedTravelers?.length}
              totalSegments={totalSegments}
              isReturn={returnSegments?.length > 0}
              infantCount={flightData?.travellerCount?.infant}
              scrollToTravellers={travellersRef.current}
            />
          </Box>
        </Box>
      )}
      <OAFooter
        onClick={onClickHandler}
        flightData={flightData}
        priceLoading={!flightData?.id}
        showPriceBreakup
        displayCoinBanner={!smbFeat}
        isBoxShadow={false}
      />
      <OASwipeableDrawer
        title="Confirm Itinerary"
        open={isConfirmItineraryOpen}
        onOpen={toggleItineraryDrawer}
        onClose={toggleItineraryDrawer}
        isContainer={false}
        maxHeight={90}
      >
        <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <OAFlightRoute
            from={onwardFromAndTo?.from}
            to={onwardFromAndTo?.to}
            icon="flight-take-off.svg"
          />
          <Typography variant="body3" color="#5A6068" fontWeight={600}>
            {onwardDurationInfo}
          </Typography>
        </Container>
        {onwardSegments?.map((segment: ISegment, index: number) => (
          <Box my="8px">
            <OAFlightSummary
              key={segment.segmentId}
              segmentIndex={index}
              data={segment}
              layovers={onwardLayovers}
              tripType={travelDetail?.tripType}
              isConfirmItinerary={true}
              nearbyAirportNudge={
                nearbyAirportNudges?.onward?.find((nudge) => nudge?.index === index)?.message
              }
            />
          </Box>
        ))}
        {returnSegments?.length > 0 && (
          <Container sx={{ mt: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <OAFlightRoute
              from={returnFromAndTo?.from}
              to={returnFromAndTo?.to}
              icon="flight-land.svg"
            />
            <Typography variant="body3" color="#5A6068" fontWeight={600}>
              {returnDurationInfo}
            </Typography>
          </Container>
        )}
        {returnSegments?.map((segment: ISegment, index: number) => (
          <Box my="8px">
            <OAFlightSummary
              key={segment.segmentId}
              segmentIndex={index}
              data={segment}
              layovers={returnLayovers}
              tripType={travelDetail?.tripType}
              isConfirmItinerary={true}
              nearbyAirportNudge={
                nearbyAirportNudges?.return?.find((nudge) => nudge?.index === index)?.message
              }
            />
          </Box>
        ))}
        <Box px="16px" my="20px">
          <Typography variant="h3">Travellers</Typography>
          <Box mt="16px">
            {userDetails?.map((user: any, index: number) => (
              <Typography variant="body2" mb={index === userDetails?.length - 1 ? '8px' : '16px'}>
                {user?.fullName}, {user?.age}
              </Typography>
            ))}
          </Box>
        </Box>
        {smbFeat && (seatRows || mealsArray || baggageArray) && (
          <Box mb={premiumUserFeat ? '28px' : '24px'}>
            <AddOnsSummary onAddClick={toggleSmbDrawer} />
          </Box>
        )}
        {!isPremiumUser && premiumUserFeat && premiumGoldDetails && (
          <Box px="16px" mb="20px">
            <OAPremiumMembership
              goldDetails={premiumGoldDetails}
              convenienceFee={flightData?.orderAmount?.convenienceFee}
              isPremiumSelected={isPremiumSelected}
              setIsPremiumSelected={setIsPremiumSelected}
            />
          </Box>
        )}
        <Typography px="16px" mb="16px" variant="h3">
          Fare summary
        </Typography>
        <OAFareSummary
          data={flightData}
          selectedSmbData={selectedSmbData}
          isPremiumSelected={isPremiumSelected}
          premiumGoldDetails={premiumGoldDetails}
          isPremiumUser={isPremiumUser}
        />
        {flightData?.international && esimOffer && (
          <Box mb="36px" px="16px">
            <OAImage src="esim.svg" folder="offer" alt="esim" width="100%" />
          </Box>
        )}
        <Box
          px="16px"
          position="sticky"
          bottom="0px"
          py="12px"
          sx={{ bgcolor: 'white' }}
          zIndex={10}
          borderTop="1px solid #DEE2E1"
        >
          {isMock && (
            <Box sx={{ mb: '24px' }}>
              <OARadioGroup
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: 'black',
                  mb: '10px',
                  '&.Mui-focused': {
                    color: 'text.primary',
                  },
                }}
                label="Mock Payment Status"
                options={MOCK_PAYMENT_STATUS}
                value={mockPaymentStatus ?? ''}
                onChange={handleMockPaymentStatus}
              />
            </Box>
          )}
          <OAButton
            variant="contained"
            color="secondary"
            sx={{ width: '100%' }}
            onClick={handleSubmitOrder}
          >
            Proceed to Payment
            {/* {isRetryPayment ? 'Retry Payment' : 'Make Payment'} */}
          </OAButton>
        </Box>
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title={
          flightUnavailableError ? (
            <OAImage src="error-icon.svg" folder="icons" alt="error" />
          ) : (
            <OAImage
              src={
                (priceCheckData?.newTotalAmount ?? priceCheckSmb?.newTotalAmount) >
                (flightData?.orderAmount?.totalAmount ?? totalSelectedFlightPrice)
                  ? 'price-increase.svg'
                  : 'price-decrease.svg'
              }
              folder="icons"
              alt="price-increase"
            />
          )
        }
        open={priceCheckStatusDrawer}
        onOpen={togglePriceCheckDrawer}
        onClose={togglePriceCheckDrawer}
        isContainer={false}
        isError={true}
      >
        <PriceCheckStatus
          originalPrice={
            !flightData?.orderAmount ? totalSelectedFlightPrice : orderAmountIncludingSmb
          }
          data={priceCheckSmb ?? priceCheckData}
          isFareUpdateAction={isFareUpdateAction}
          handleAction={priceCheckSmb ? onClickHandler : handleCreateOrderWithNewPrice}
        />
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
        open={isApiError}
        onOpen={toggleApiError}
        onClose={toggleApiError}
        isContainer={false}
        isError={true}
      >
        <SomethingWentWrong />
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
        open={isPaymentDone}
        onOpen={toggleApiError}
        onClose={toggleApiError}
        isContainer={false}
        isError={true}
      >
        <ErrorPaymentDone />
      </OASwipeableDrawer>
    </>
  );
};

export default Review;
