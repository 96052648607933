import { IFlight, ISegment } from '../type';

export const classifyDepartureTime = (time: string): string => {
  const [hours, minutes] = time?.split(':')?.map(Number);
  const totalMinutes = hours * 60 + minutes;
  const timeRanges = [
    { end: 6 * 60, label: 'EARLY_MORNING' }, // 12 AM - 6 AM
    { end: 12 * 60, label: 'MORNING' }, // 6 AM - 12 PM
    { end: 16 * 60, label: 'MID_DAY' }, // 12 PM - 4 PM
    { end: 20 * 60, label: 'EVENING' }, // 4 PM - 8 PM
    { end: 24 * 60, label: 'NIGHT' }, // 8 PM - 12 AM
  ];

  const foundRange = timeRanges.find((range) => totalMinutes < range.end);
  return foundRange ? foundRange.label : 'UNKNOWN';
};

export function filterFlights(flights: IFlight[], filters: Record<string, string[]>): IFlight[] {
  return Object.entries(filters).reduce((acc, [key, values]) => {
    const handler = filterHandlers[key];
    return handler ? handler(acc, values) : acc;
  }, flights);
}

interface FilterHandlers {
  [key: string]: (flights: IFlight[], values: string[]) => IFlight[];
}

const isFlightAtAirport = (segment: ISegment, airportCode: string) =>
  segment.departure.airport.iata === airportCode || segment.arrival.airport.iata === airportCode;

const isFlightDepartureAirport = (segment: ISegment, airportCode: string) =>
  segment.departure.airport.iata === airportCode;

const isFlightArrivalAirport = (segment: ISegment, airportCode: string) =>
  segment.arrival.airport.iata === airportCode;

const isFlightWithAirline = (segment: ISegment, airlineCodes: string[] | string) => {
  return (
    airlineCodes?.includes(segment?.airline?.code) ||
    (airlineCodes?.includes('IX') && segment?.airline?.code === 'I5') ||
    (airlineCodes?.includes('I5') && segment?.airline?.code === 'IX')
  );
};

const filterHandlers: FilterHandlers = {
  airports: (flights, values) =>
    flights?.filter((flight) =>
      flight.segments?.some((segment) => values?.some((value) => isFlightAtAirport(segment, value)))
    ),
  airlines: (flights, values) =>
    flights?.filter((flight) =>
      flight?.segments?.some((segment) =>
        values?.some((value) => isFlightWithAirline(segment, value))
      )
    ),
  stops: (flights, values) => {
    // Check for the presence of "1+" in the filter values
    const hasOnePlus = values.includes('1+');
    return flights.filter((flight) => {
      const stops = flight.stops.value;
      if (hasOnePlus && stops >= 2) {
        return true; // Include flights with 2 or more stops if "1+" is one of the filter values
      }
      // Convert stops to string to compare with values like "0"
      return values.includes(stops.toString());
    });
  },
  onwardDeparture: (flights, values) =>
    flights?.filter((flight) =>
      values?.includes(classifyDepartureTime(flight?.segments[0]?.departure?.time))
    ),
  returnDeparture: (flights, values) =>
    flights?.filter((flight) =>
      values?.includes(classifyDepartureTime(flight?.segments[0]?.departure?.time))
    ),
  returnStops: (flights, values) => {
    const hasOnePlus = values?.includes('1+');
    return flights?.filter((flight) => {
      const stops = flight?.returnStops?.value ?? 0;
      if (hasOnePlus && stops >= 2) {
        return true;
      }
      return values?.includes(stops?.toString());
    });
  },
  originNearbyAirports: (flights, values) =>
    flights?.filter((flight) =>
      flight?.segments?.some((segment) =>
        values?.some((value) => isFlightDepartureAirport(segment, value))
      )
    ),
  destinationNearbyAirports: (flights, values) =>
    flights?.filter((flight) => {
      const lastSegment = flight?.segments[flight.segments.length - 1];
      return values?.some((value) => isFlightArrivalAirport(lastSegment, value));
    }),
};

export function filterFlightsBy(flights: IFlight[], filters: Record<string, string[]>): IFlight[] {
  if (!filters || Object.keys(filters).length === 0) {
    return flights;
  }

  let filteredFlights = [...flights];

  Object?.entries(filters)?.forEach(([key, values]) => {
    const handler = filterHandlers[key];
    if (handler) {
      filteredFlights = handler(filteredFlights, values);
    }
  });

  return filteredFlights;
}
