import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { getFormatedDate } from '../../utils';
import { BE_DATE } from '../../constants';
import { REHYDRATE } from 'redux-persist';

export interface UserInputState {
  traveller: any;
  travelDetail: {
    tripType: 'O' | 'R'; // O - One way and R - Round
    tripStart: {
      date: string | null;
    };
    tripEnd?: {
      date: string | null;
    };
    travellerCount: {
      adult: number;
      child: number;
      infant: number;
    };
    from: {
      iata: string;
      name: string;
      city: string;
    };
    to: {
      iata: string;
      name: string;
      city: string;
    };
    cabinType: string;
    fareGroup?: string;
  };
  isCoinsApplied: boolean;
  selectedBookingsTab: string;
}

/**
 * Default state object with initial values.
 */
const initialState: any = {
  traveller: [],
  travelDetail: {
    tripType: 'O',
    tripStart: {
      date: getFormatedDate(new Date(), BE_DATE),
    },
    tripEnd: {
      date: null,
    },
    travellerCount: {
      adult: 1,
      child: null,
      infant: null,
    },
    from: {
      iata: null,
      city: null,
      name: null,
      country: null,
    },
    to: {
      iata: null,
      city: null,
      name: null,
      country: null,
    },
    cabinType: 'ECONOMY',
    fareGroup: 'REGULAR'
  },
  isCoinsApplied: false,
  selectedBookingsTab: 'UPCOMING',
} as const;

/**
 * Create a slice as a reducer containing actions.
 */
export const userInputSlice = createSlice({
  name: 'userInput',
  initialState,
  reducers: {
    setTraveller: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.traveller>
    ) => {
      state.traveller = action.payload;
    },
    setTravelDetail: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.travelDetail>
    ) => {
      state.travelDetail = action.payload;
    },
    setIsCoinsApplied: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.isCoinsApplied>
    ) => {
      state.isCoinsApplied = action.payload;
    },
    setBookingsTab: (
      state: Draft<typeof initialState>,
      action: PayloadAction<typeof initialState.selectedBookingsTab>
    ) => {
      state.selectedBookingsTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(REHYDRATE, (state, action: any) => {
      const incoming = action.payload;
      if (incoming && action?.key === 'userInput') {
        state.travelDetail.from = incoming?.travelDetail?.from;
        state.travelDetail.to = incoming?.travelDetail?.to;
        let currentDate = new Date();
        let persistedDate = new Date(incoming?.travelDetail?.tripStart?.date);
        if (persistedDate < currentDate) {
          currentDate.setDate(currentDate?.getDate() + 1);
          state.travelDetail.tripStart.date = getFormatedDate(currentDate, BE_DATE);
        } else {
          state.travelDetail.tripStart.date = incoming?.travelDetail?.tripStart?.date;
        }
      }
    });
  },
});

// A small helper of user state for `useSelector` function.
export const getUserInputState = (state: { userInput: UserInputState }) => state.userInput;

export const { setTraveller, setTravelDetail, setIsCoinsApplied, setBookingsTab } =
  userInputSlice.actions;

export default userInputSlice.reducer;
