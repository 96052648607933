const FilterCount = ({ count }: { count: number }) => {
  return (
    <span style={{
      backgroundColor: '#C4F8EA',
      borderRadius: '50%',
      padding: '1px 7px',
      marginLeft: '4px',
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: '400',
    }}>
      {count}
    </span>
  );
};

export default FilterCount;
