import { useEffect, useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import DateInputField from './DateInputField';
import DateDrawer from './DateDrawer';
import { addDays, isAfter, parseISO } from 'date-fns';
import { TTravelDetail } from '../../../type';
import { getFormatedDate } from '../../../utils';
import { BE_DATE } from '../../../constants';
import { useGetFarePriceMutation } from '../../../services/flightSearchApi';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from '../../../store';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';

const TravelDate = ({ localTravelDetail, setLocalTravelDetail, onSelect }: any) => {
  const [getFarePrice] = useGetFarePriceMutation();
  const { fareCalendarFeat } = useSelector(getFeatureFlag);

  const [returnClicked, setReturnClicked] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<'departure' | 'return'>('departure');
  const [selectedDate, setSelectedDate] = useState<any>('');
  const [prices, setPrices] = useState<any>();

  const convertDateToISO: any = (dt: string) => (dt ? parseISO(dt) : null);
  let dateSelected = getFormatedDate(selectedDate?.to, BE_DATE);

  useEffect(() => {
    if (localTravelDetail?.tripType === 'O') {
      setSelectedDate(convertDateToISO(localTravelDetail?.tripStart?.date!));
    } else {
      setSelectedDate({
        from: convertDateToISO(localTravelDetail?.tripStart?.date!),
        to:
          localTravelDetail?.tripType === 'R'
            ? localTravelDetail?.tripEnd?.date
              ? convertDateToISO(localTravelDetail?.tripEnd?.date!)
              : selectedDate?.to
              ? convertDateToISO(dateSelected)
              : addDays(convertDateToISO(localTravelDetail?.tripStart?.date), 1)
            : null,
      });
    }
  }, [isOpen]);

  const toggleDrawer = (value?: any, isBottom?: boolean) => {
    if (!isBottom && isOpen && !localTravelDetail?.tripEnd?.date) {
      // Drawer is closing without applying the date
      if (localTravelDetail?.tripType === 'R' && !selectedDate?.to) {
        setSelectedDate(selectedDate?.from);
        setLocalTravelDetail({
          ...localTravelDetail,
          tripEnd: {
            date: null,
          },
          tripType: 'O',
        });
      }
    }

    if (value) setSelectedType(value);
    if (!isBottom) setIsOpen(!isOpen);
    if (!value) {
      setLocalTravelDetail({
        ...localTravelDetail,
        tripType: (!isBottom && isOpen && !localTravelDetail?.tripEnd?.date ? 'O' : 'R') ?? 'O',
      });
    }
  };

  const handleDateChange = (dt: any) => {
    if (selectedDate?.from && selectedDate?.to) {
      if (returnClicked && isAfter(dt?.from, selectedDate?.to)) {
        setSelectedDate({
          from: selectedDate?.from,
          to: dt?.from,
        });
        setReturnClicked(false);
      } else {
        setSelectedDate({
          from: selectedDate?.from !== dt?.from ? dt?.from : dt?.to,
          to: null,
        });
      }
    } else {
      if (dt) setSelectedDate(dt);
    }
  };

  const handleReturn = () => {
    setSelectedDate({
      from: selectedDate?.from ?? selectedDate,
      to: null,
    });
    setLocalTravelDetail({
      ...localTravelDetail,
      tripType: 'R',
    });
    setSelectedType('return');
  };

  const handleCancelReturn = () => {
    setSelectedDate(selectedDate?.from);
    setLocalTravelDetail({
      ...localTravelDetail,
      tripEnd: {
        date: null,
      },
      tripType: 'O',
    });
    setSelectedType('departure');
  };

  const handleApplyDate = () => {
    const updatedTravelDetail: TTravelDetail = {
      ...localTravelDetail,
      tripType: selectedDate?.from && selectedDate?.to ? 'R' : 'O',
      tripStart: {
        date:
          getFormatedDate(selectedDate?.from, BE_DATE) ?? getFormatedDate(selectedDate, BE_DATE),
      },
      tripEnd: {
        date: getFormatedDate(selectedDate?.to, BE_DATE),
      },
    };
    onSelect(updatedTravelDetail);
    setIsOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DateInputField
            value={
              localTravelDetail?.tripStart?.date
                ? convertDateToISO(localTravelDetail?.tripStart?.date!)
                : null
            }
            label="departure"
            onClick={() => {
              const draft = {
                cabinType: localTravelDetail?.cabinType,
                travellerCount: localTravelDetail?.travellerCount,
                from: localTravelDetail?.from,
                to: localTravelDetail?.to,
                isReturn: false,
              };
              if (fareCalendarFeat) {
                getFarePrice(draft).then((res: any) => {
                  setPrices(res?.data?.data?.results);
                });
              }
              toggleDrawer('departure', false);
            }}
            focused={false}
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ position: 'relative' }}>
            {localTravelDetail?.tripEnd?.date && (
              <IconButton
                onClick={handleCancelReturn}
                disableRipple
                sx={{
                  position: 'absolute',
                  top: 17,
                  right: 3,
                  zIndex: 1,
                  color: 'grey.800',
                }}
              >
                <CancelIcon fontSize="small" />
              </IconButton>
            )}
            <DateInputField
              value={localTravelDetail?.tripType === 'R' ? localTravelDetail?.tripEnd?.date : null}
              label="return"
              onClick={() => {
                const draft = {
                  cabinType: localTravelDetail?.cabinType,
                  travellerCount: localTravelDetail?.travellerCount,
                  from: localTravelDetail?.from,
                  to: localTravelDetail?.to,
                  isReturn: true,
                };
                if (fareCalendarFeat) {
                  getFarePrice(draft).then((res: any) => {
                    setPrices(res?.data?.data?.results);
                  });
                }
                toggleDrawer('return', false);
                setReturnClicked(true);
                handleReturn();
              }}
              focused={false}
            />
          </Box>
        </Grid>
      </Grid>
      <DateDrawer
        isOpen={isOpen}
        title={`Select date`}
        localTravelDetail={localTravelDetail}
        selectedDate={selectedDate}
        selectedType={selectedType}
        prices={prices}
        setPrices={setPrices}
        toggleDrawer={toggleDrawer}
        handleDateChange={handleDateChange}
        handleCancelReturn={handleCancelReturn}
        handleApplyDate={handleApplyDate}
        handleReturn={handleReturn}
        getFarePrice={getFarePrice}
      />
    </>
  );
};

export default TravelDate;
