import { FC, SyntheticEvent, useEffect } from 'react';
import { Box, Fade, IconButton, Snackbar, SnackbarContent, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessage } from '../../store/slices/snackbarSlice';
interface SnackbarProps {
  isOpen?: boolean;
  onClose?: () => void;
  message?: string;
}

export const OASnackbar: FC<SnackbarProps> = ({ isOpen, onClose, message }) => {
  const { message: snackbarMessage, severity } = useSelector((state: any) => state.snackbar);
  const dispatch = useDispatch();

  const handleClose: (event: SyntheticEvent | Event, reason?: string) => void = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(clearMessage());
  };

  // Force close after 7 seconds
  useEffect(() => {
    if (snackbarMessage) {
      const timer = setTimeout(() => {
        handleClose({} as Event, 'timeout');
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, [snackbarMessage]);

  return (
    <Snackbar
      open={!!snackbarMessage}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Fade}
      sx={{
        bottom: 84,
      }}
    >
      <SnackbarContent
        sx={{
          backgroundColor: ['error'].includes(severity) ? `${severity}.main` : 'text.primary', // MUI theme error color
        }}
        message={
          <Box display="flex" alignItems="center" justifyContent="space-between" color="#fdfdfd">
            <Typography variant="body2" color="#FDFDFD">
              {snackbarMessage}
            </Typography>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 500,
                  color: ['error'].includes(severity) ? '#FDFDFD' : 'primary.main',
                }}
              >
                Okay
              </Typography>
            </IconButton>
          </Box>
        }
      />
    </Snackbar>
  );
};
