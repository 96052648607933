import { Box, Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { OAImage } from '../OAImage';

interface OACheckboxProps {
  name?: string;
  label?: string;
  value?: string;
  checked: boolean;
  onEdit?: () => void;
  onChange?: any;
  isDisabled?: boolean;
  labelStyle?: any;
  sx?: any;
}

export const OACheckbox = ({ 
  name, 
  label, 
  value, 
  checked, 
  onEdit, 
  onChange, 
  isDisabled,
  labelStyle,
  sx
}: OACheckboxProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        ...sx
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            sx={{
              '&.Mui-checked': {
                color: '#009E82',
              },
            }}
            value={value}
            checked={checked}
            onChange={(event) => onChange(event, name)}
            disabled={isDisabled}
          />
        }
        label={<Typography variant="body1" sx={labelStyle}>{label}</Typography>}
        sx={{ marginRight: 'auto' }} // This pushes the FormControlLabel to the left and the IconButton to the right
      />
      {onEdit && (
        <IconButton aria-label="edit" onClick={onEdit} sx={{ p: 0 }}>
          <OAImage src="edit.svg" folder="icons" alt="edit" />
        </IconButton>
      )}
    </Box>
  );
};
