import { Box, Button, Typography } from "@mui/material";
import { getBaggageCount, getMealCount, getSeatCount, getSelectedBaggageForSegment, getSelectedSeatsForSegment, getTotalPriceBasedOnBaggageSegment, getTotalPriceBasedOnMealSegment, getTotalPriceBasedOnSeatSegment } from "../../../store/slices/flightOrderSlice";
import { formatToINR } from "../../../utils";

interface BottomBarProps {
  totalPrice: any;
  addOnType: 'Seat' | 'Meal' | 'Baggage'
  totalAddOnCount: number;
  smbData: any;
  discountPrice?: any;
  isDiscountApplied?: boolean;
  onClick: () => void;
  selectedSegment: { from: string, to: string };
  isLoading: boolean;
}

const BottomBar: React.FC<BottomBarProps> = ({
  totalPrice,
  addOnType,
  totalAddOnCount,
  smbData,
  discountPrice,
  isDiscountApplied,
  onClick,
  selectedSegment,
  isLoading
}) => {

  const getCount = (addOn: string) => {
    switch (addOn) {
      case 'Seat':
        return getSeatCount(smbData, selectedSegment);
      case 'Meal':
        return getMealCount(smbData, selectedSegment);
      case 'Baggage':
        return getBaggageCount(smbData, selectedSegment);
      default:
        return 0;
    }
  };

  const getPrice = (addOn: string) => {
    switch (addOn) {
      case 'Seat':
        return getTotalPriceBasedOnSeatSegment(smbData, selectedSegment);
      case 'Meal':
        return getTotalPriceBasedOnMealSegment(smbData, selectedSegment);
      case 'Baggage':
        return getTotalPriceBasedOnBaggageSegment(smbData, selectedSegment);
      default:
        return 0;
    }
  };
  const getAncillaryData = (addOn: string) => {
    switch(addOn) {
      case 'Seat':
        return getSelectedSeatsForSegment(smbData, selectedSegment);
      case 'Baggage':
        return getSelectedBaggageForSegment(smbData, selectedSegment);
      default: 
      return '';
    }
  }
  const count = getCount(addOnType);
  const price = getPrice(addOnType);
  const ancillaryData = getAncillaryData(addOnType);

  return (
    <Box
      position="sticky"
      bottom={0}
      display="flex"
      flexDirection="column"
      width="100%"
      bgcolor="white"
    >
      <Box p="5px 18px" bgcolor="#FFF4CE" display="flex" justifyContent="space-between">
        <Box display="flex">
          {addOnType}(s):{' '}
          <Typography ml="5px" fontWeight={600}>
            {ancillaryData} ({count}/{totalAddOnCount})
          </Typography>
        </Box>
        {count > 0 && <Typography>{count === 0 ? 'Free' : formatToINR(price)}</Typography>}
      </Box>
      <Box display='flex' justifyContent='space-between' p='10px 20px'>
        <Box display='flex' alignItems='center'>
          {isDiscountApplied && <Typography sx={{ textDecoration: 'line-through', color: '#5A6068' }}>₹{totalPrice?.toFixed(2)}</Typography>}
          <Typography variant="h2" ml={isDiscountApplied ? '6px' : 'revert'}>
            {isDiscountApplied ? formatToINR(discountPrice!) : formatToINR(totalPrice)}
          </Typography>
        </Box>
        <Box>
          <Button onClick={onClick} sx={{ bgcolor: 'black !important' }} variant="contained">Continue</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default BottomBar;