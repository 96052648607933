import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';

export const OATextField = ({ InputProps, ...rest }: TextFieldProps) => {
  const customInputProps = {
    ...InputProps,
    autoComplete: 'off',
    'data-lpignore': true,
    'data-form-type': 'other',
    onFocus: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {},
  };

  return <TextField {...rest} variant="filled" autoComplete="off" InputProps={customInputProps} />;
};
