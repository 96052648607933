import { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { OAImage } from '../../components';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';

interface CoinsBannerProps {
  title: string;
  subTitle: string;
  titleSx: SxProps;
  subTitleSx: SxProps;
}
const CoinsBanner: FC<CoinsBannerProps> = ({ title, subTitle, titleSx, subTitleSx }) => {
  const { premiumUserFeat } = useSelector(getFeatureFlag);

  return (
    <Box sx={{ position: 'absolute', bottom: 50, left: 20 }}>
      <Typography variant="h3" sx={{ ...titleSx }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ ...subTitleSx }} display='flex' alignItems='center'>
        {premiumUserFeat && <OAImage
          src="3xNiyo_coin.png"
          alt="coins"
          sx={{ mx: '5px', height: '20px', width: '20px' }}
        />}{subTitle}
      </Typography>
      {!premiumUserFeat && <><Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <Typography variant='body2' component="sup" sx={{ color: '#FFFFFF', fontSize: '0.7em', verticalAlign: 'super', ml: '2px' }}>
          *
        </Typography>
        <Typography variant='body2' component="span" sx={{ color: '#FFFFFF' }}>
          on UPI & Niyo Cards
        </Typography>
      </Box>
      <Box display="flex" mt="15px" mb="10px">
        <OAImage
          src="3xNiyo_coin.png"
          alt="coins"
          sx={{ mx: '5px', height: '18px', width: '18px' }}
        />
        <Typography variant="body2" sx={{ color: '#FFFFFF', ml: '4px' }}>
          Redeem Niyo coins for additional discount
        </Typography>
      </Box></>}
    </Box>
  );
};

export default CoinsBanner;
