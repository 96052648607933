import { FC, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { OALoadingLottie } from './OALoadingLottie';
import { OASwipeableDrawer } from './basic/OASwipeableDrawer';
import { OAButton } from './basic/OAButton';
import { OACountdown } from './OACountdown';
import { useCancelTransactionApiMutation } from '../services/bookingsApi';
import { eventsTracker } from '../utils/ctEventsTracking';
import posthog from 'posthog-js';
import { OAImage } from './OAImage';

interface OALoadingPaymentProps {
  setIsPaymentLoading: any;
  setIsRetryPayment: any;
  transactionId: string;
  timeOutInSeconds: number;
}
export const OALoadingPayment: FC<OALoadingPaymentProps> = ({
  setIsPaymentLoading,
  setIsRetryPayment,
  transactionId,
  timeOutInSeconds,
}) => {
  const [cancelTransactionApi] = useCancelTransactionApiMutation();
  const [cancelTransaction, setCancelTransaction] = useState<boolean>(false);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const toggleDrawer = () => setCancelTransaction((prev) => !prev);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const onComplete = () => {
    setIsTimeout(true);
  };

  const cancelTransactionHandle = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Loader',
        ctaAction: 'ConfirmCancel',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: 'User has cancelled the transaction',
        },
      },
      posthog
    );
    cancelTransactionApi(transactionId).then((res: any) => {
      setCancelTransaction(false);
      setIsPaymentLoading(false);
      setIsRetryPayment(true);
      if (
        window.flutter_inappwebview &&
        window.niyo_cancel_current_transaction &&
        typeof window.niyo_cancel_current_transaction === 'function'
      ) {
        window.niyo_cancel_current_transaction();
      }
    });
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: '#FFFFFF',
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 40,
            left: 20,
          }}
          onClick={isTimeout ? cancelTransactionHandle : toggleDrawer}
        >
          <OAImage src="close.svg" folder="icons" alt="close" />
        </Box>
        {isTimeout ? (
          <OAImage src="error-icon.svg" folder="icons" alt="error" />
        ) : (
          <OALoadingLottie />
        )}
        <Typography variant="body2" sx={{ fontWeight: 600, mt: '20px', mb: '8px' }}>
          {isTimeout ? (
            'Request timed out'
          ) : (
            <OACountdown duration={timeOutInSeconds} onComplete={onComplete} />
          )}
        </Typography>
        <Container>
          <Box
            display="flex"
            alignItems="flex-start"
            sx={{ p: '15px', bgcolor: isTimeout ? '#FFE5E2' : '#F4F6F5', borderRadius: '10px' }}
          >
            <OAImage
              src={isTimeout ? 'info-icon-error.svg' : 'info-icon.svg'}
              folder="icons"
              alt="info"
            />
            {isTimeout ? (
              <Typography variant="body3" sx={{ ml: '10px' }}>
                The request will be seen in your bank app. Please do not approve it. In case you
                have already approved the request, your money will be refunded within 7 working
                days.
              </Typography>
            ) :
              (
                <Typography variant="body3" sx={{ ml: '10px' }}>
                  We are currently checking the status of your payment. Open your UPI app on your
                  smartphone. Complete the payment by entering your UPI PIN.
                </Typography>
              )}
          </Box>
        </Container>

        {!isTimeout && (
          <Typography
            component="p"
            variant="body2"
            sx={{ color: '#C01100', mt: '40px', textAlign: 'center', fontWeight: 500 }}
            onClick={toggleDrawer}
          >
            Cancel transaction
          </Typography>
        )}

        {!isTimeout && (
          <Typography
            variant="body3"
            sx={{
              p: '20px 40px',
              position: 'fixed',
              bottom: 0,
              left: 0,
              textAlign: 'center',
              color: 'text.secondary',
            }}
          >
            Please do not click refresh or go back until the itinerary page is displayed.
          </Typography>
        )}

        <OASwipeableDrawer
          sx={{ zIndex: 999999 }}
          title={<OAImage src="error-icon.svg" folder="icons" alt="error" />}
          open={cancelTransaction}
          onOpen={toggleDrawer}
          onClose={toggleDrawer}
          isContainer={false}
        >
          <Container sx={{ pb: '24px' }}>
            <>
              <Typography variant="h6" mb="10px">
                Cancel transaction?
              </Typography>
              <Typography>
                You are midway through a transaction. Your flight booking will get cancelled
              </Typography>
              <OAButton
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: '24px' }}
                onClick={cancelTransactionHandle}
              >
                Confirm
              </OAButton>
            </>
          </Container>
        </OASwipeableDrawer>
      </Box>
    </>
  );
};
