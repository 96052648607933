import { Box, Container, Typography } from '@mui/material';
import { OAButton, OALoadingOverlay } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { useFlightSearchQuery } from '../../services/flightSearchApi';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { setError } from '../../store/slices/flightOrderSlice';

const PriceCheckStatus = ({ originalPrice, data, handleAction, isFareUpdateAction }: any) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { travelDetail } = useSelector(getUserInputState);
  const { travellerCount = {}, tripStart, tripEnd, cabinType, fareGroup }: any = travelDetail;
  const { adult = 0, child = 0, infant = 0 } = travellerCount;
  const [startTime, setStartTime] = useState(Date.now());

  const tripType = tripEnd?.date ? 'R' : 'O';
  const traveller = `adults=${adult}&childs=${child}&infants=${infant}`;

  const fromTo =
    `${travelDetail?.from?.iata}` +
    `&fromCity=${travelDetail?.from?.city}` +
    `&to=${travelDetail?.to?.iata}` +
    `&toCity=${travelDetail?.to?.city}` +
    `&fromDate=${travelDetail?.tripStart?.date}` +
    `${travelDetail?.tripEnd?.date ? `&toDate=${travelDetail?.tripEnd?.date}` : ''}`;

  let payload = { ...travelDetail };

  if (!payload.tripEnd?.date) {
    delete payload.tripEnd;
  }

  const { refetch } = useFlightSearchQuery(payload, { skip: !data });

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (data?.code === 533 || data?.code === 513) {
      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'FareUpdate',
          ctaAction: data?.newTotalAmount > originalPrice ? 'Increase' : 'Decrease',
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    }
  }, [data]);

  const differenceInAmount = Math.abs(data?.newTotalAmount - originalPrice).toFixed(2);

  let redirectUrl = `/results?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`;
  if (fareGroup) {
    redirectUrl += `&fareGroup=${fareGroup}`;
  }

  return (
    <Container sx={{ pb: '24px' }}>
      {(data?.code === 532 || data?.code === 512) && (
        <>
          <Typography variant="h6" mb="10px">
            Flight unavailable
          </Typography>
          <Typography>{data?.message}</Typography>
          <OAButton
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ mt: '24px' }}
            onClick={() => {
              refetch();
              dispatch(setError(null));
              const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
              eventsTracker(
                {
                  flowName: 'Flight',
                  screenName: 'Alert',
                  ctaAction: 'FlightUnavailable',
                  screenDuration: totalScreenDuration?.toString(),
                  UserAction: {
                    userAction: 'FlightUnavailable',
                  },
                },
                posthog
              );
              navigate(redirectUrl);
            }}
          >
            Back to Flights
          </OAButton>
        </>
      )}
      {(data?.code === 533 || data?.code === 513) && (
        <>
          <Typography variant="h6" mb="10px">
            Fare has {data?.newTotalAmount > originalPrice ? 'increased' : 'decreased'} by ₹
            {differenceInAmount}
          </Typography>
          <Typography>{data?.message}</Typography>
          <Box display="flex" mt="20px">
            {/* <OAButton
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mr: '20px' }}
              onClick={() => {
                const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

                eventsTracker(
                  {
                    flowName: 'Flight',
                    screenName: 'FareUpdate',
                    ctaAction: 'Back',
                    screenDuration: totalScreenDuration?.toString(),
                  },
                  posthog
                );
                navigate(
                  `/results?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`
                );
                dispatch(setError(null));
              }}
            >
              Back to Flights
            </OAButton> */}
            {isFareUpdateAction ? <OALoadingOverlay /> :
              <OAButton disabled={isFareUpdateAction} fullWidth variant="contained" color="secondary" onClick={handleAction}>
                Continue
              </OAButton>
            }
          </Box>
        </>
      )}
    </Container>
  );
};

export default PriceCheckStatus;
