export const SEARCH_FLIGHT = `${process.env.REACT_APP_API_URL_VERSION}/flight/search`;
export const AIRPORT_SEARCH = `${process.env.REACT_APP_API_URL_VERSION}/airports/search`;
export const RECENT_FLIGHT_SEARCH = `${process.env.REACT_APP_API_URL_VERSION}/flight/search/aggregates`;
export const TRAVELLERS = `${process.env.REACT_APP_API_URL_VERSION}/travellers`;
export const CREATE_FLIGHT_ORDER = `${process.env.REACT_APP_API_URL_VERSION}/flight/order`;
export const FLIGHT_ORDER_SUBMIT = `${process.env.REACT_APP_API_URL_VERSION}/flight/order/submit`;
export const MY_BOOKINGS = `${process.env.REACT_APP_API_URL_VERSION}/flight/orders`;
export const TRAVELLERS_IN_FLIGHT_ORDER = `${process.env.REACT_APP_API_URL_VERSION}/flight/order/travellers`;
export const FARE_AND_MINI_RULE = `${process.env.REACT_APP_API_URL_VERSION}/flight/search/fareAndMiniRule`;
export const INITIALIZE_PAYMENT = `${process.env.REACT_APP_API_URL_VERSION}/payment/initialize`;
export const USER = `${process.env.REACT_APP_API_URL_VERSION}/user`;
export const PAYMENT_STATUS = `${process.env.REACT_APP_API_URL_VERSION}/payment/status`;
export const CANCELLATION_INFO = `${process.env.REACT_APP_API_URL_VERSION}/flight/order/cancel/info`;
export const CANCEL_ORDER = `${process.env.REACT_APP_API_URL_VERSION}/flight/order/cancel`;
export const CANCEL_TRANSACTION = `${process.env.REACT_APP_API_URL_VERSION}/payment/cancel`;
export const NEARBY_AIRPORT = `${process.env.REACT_APP_API_URL_VERSION}/airport/nearby`;
export const DOWNLOAD_ETICKET = `${process.env.REACT_APP_API_URL_VERSION}/flight/order/attachment`;
export const GET_FARE_PRICE_CALENDAR = `${process.env.REACT_APP_API_URL_VERSION}/flight/fareCalendar?tag=true`;
export const EARN_CAMPAIGNS = `${process.env.REACT_APP_API_URL_VERSION}/user/earn-campaigns`;
export const SMB_ANCILLARY = `${process.env.REACT_APP_API_URL_VERSION}/flight/order/ancillary`;
export const NIYO_PREMIUM_PLANS = `${process.env.REACT_APP_API_URL_VERSION}/niyo/premium-plans`;
